import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useGetUser from '@core/hooks/useGetUser'
import useProfile from '@core/hooks/useProfile'
import useGetPermissions from 'app/features/permissions/get/hooks/useGetPermissions'
import useUpdateUser from '../../edit/hooks/useUpdateUser'
import useDeleteUser from '../hooks/useDeleteUser'

const useEditUserState = () => {
    const navigate = useNavigate()
    const { userUid } = useParams()
    const { hasAdminPermissions } = useProfile()
    const [ open, setOpen ] = useState(false)
    const { user } = useGetUser(userUid)
    const { updateUser, isLoading: isUpdating } = useUpdateUser(userUid)
    const { deleteUser, isLoading: isDeleting } = useDeleteUser()
    const { permissions } = useGetPermissions({ SearchTerm: '' })

    useEffect(() => {
        if(!hasAdminPermissions) {
            navigate('/')
        }
    }, [navigate, hasAdminPermissions])


    return {
        open,
        setOpen,
        user,
        isUpdating,
        permissions,
        updateUser,
        isDeleting,
        deleteUser
    }
}

export default useEditUserState