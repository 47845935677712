import * as roosterjs from 'roosterjs';
import { FOOTER_ELEMENT_ID, QRCODE_ELEMENT_ID } from '../constants/reportElements';

function isPageContentNonEmpty(pageEditor: roosterjs.Editor, pageEditorDiv: HTMLElement): boolean {
  // Verifica si el contenido del editor de la página está vacío
  if (pageEditor.getContent().length === 0) {
    return false;
  }

  // No considerar un <br /> como primer elemento
  let childInitialIndex = 0;
  if (
    pageEditorDiv.childNodes.length === 1 &&
    (pageEditorDiv.childNodes[0].childNodes[0] as HTMLElement)?.tagName?.toLowerCase() === 'br'
  ) {
    childInitialIndex = 1;
  }

  // Sumar la altura de los nodos relevantes
  const sumOffsetHeight = Array.from(pageEditorDiv.childNodes)
    .slice(childInitialIndex)
    .filter((node) => {
      const htmlNode = node as HTMLElement;
      const isQrCodeDivElement = htmlNode.id === QRCODE_ELEMENT_ID;
      const isFooterDivElement = htmlNode.id === FOOTER_ELEMENT_ID;
      // El editor debe tener en cuenta el código QR y el elemento del pie de página
      if (htmlNode.id && (isQrCodeDivElement || isFooterDivElement)) {
        return false;
      }

      return (
        node.nodeType === Node.ELEMENT_NODE ||
        (node.nodeType === Node.TEXT_NODE && (node.textContent?.length ?? 0) > 0)
      );
    })
    // Para los elementos de texto, solo sumar 1 para indicar que hay texto
    .reduce(
      (acc, node) =>
        acc + (node.nodeType === Node.ELEMENT_NODE ? (node as HTMLElement).offsetHeight : 1),
      0,
    );

  return sumOffsetHeight > 0;
}

export default isPageContentNonEmpty;
