function insertVariable(variableName: string) {
  return `<span class="${variableName}">{{${variableName}}}</span>`;
}

export const initialTemplate = `
<p style="text-align: center; margin: 0; line-height: 1.3;">INFORME</p>
<p style="margin: 0; line-height: 1.3;">
    NOMBRE: ${insertVariable('PatientFirstName')} ${insertVariable(
  'PatientLastName',
)}, ${insertVariable('PatientAge')}
</p>
<p style="margin: 0; line-height: 1.3;">
    ESTUDIO: ${insertVariable(
      'StudyName',
    )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FECHA: ${insertVariable(
  'StudyDate',
)}
</p>
<p style="margin: 0; line-height: 1.3;">
    DR. ${insertVariable('Doctor')}
</p>
<p style="margin: 0; line-height: 1.3;">&nbsp;</p>
`;
