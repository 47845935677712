import React, { useCallback, useMemo } from 'react';
import { GetStudyResponse } from '@core/types';

function transformDicomAge(age: string) {
  // Expresión regular para verificar el formato: 0[YY]Y
  const regex = /^0(\d{2,3})Y$/;

  // Verificar si el string coincide con el formato esperado
  const match = age.match(regex);

  if (match) {
    // Extraer el valor numérico y convertirlo a número
    const edad = parseInt(match[1], 10);

    // Agregar ' Años' al valor numérico y retornar
    return edad + ' Años';
  } else {
    // Si no coincide con el formato esperado, devolver el mismo string
    return age;
  }
}

const getFormattedDate = (studyDate?: string) => {
  if (!studyDate) return '';

  const date = new Date(studyDate);
  if (isNaN(date.getTime())) return studyDate; // Check for invalid date

  const formattedDate = date.toLocaleDateString('es-MX', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formattedDate;
};

const useGetDicomTemplateTagsInfo = ({ study }: { study?: GetStudyResponse }) => {
  const dicomAttributes: Record<string, string> = useMemo(
    () => ({
      PatientFirstName: study?.patientsFirstName || '',
      PatientLastName: study?.patientsLastName || '',
      PatientBirthdate: getFormattedDate(study?.patientsBirthDate || ''),
      PatientGender: study?.patientsGender || '',
      PatientAge: study?.patientsAge ? transformDicomAge(study?.patientsAge) : '',
      StudyName: study?.studyDescription || '',
      StudyDate: getFormattedDate(study?.studyDate),
      Doctor: study?.referringPhysiciansName || '',
      CompanyName: '',
    }),
    [study],
  );

  return useCallback(
    (dicomKey: string) => {
      return dicomAttributes[dicomKey];
    },
    [dicomAttributes],
  );
};

export default useGetDicomTemplateTagsInfo;
