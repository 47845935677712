import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: {
        ...expandDisplayFlex({ d: 'column' }),
        flex: 1,
        gap: '10px',
        paddingY: '32px',
        paddingX: {xs: '16px', md: '0'}
    }
});

export default useSx;
