import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerWithLabel from '@components/DatePickerWithLabel/DatePickerWithLabel';
import DropdownWithLabel from '@components/DrodpdownWithLabel';
import InputWithLabel from '@components/InputWithLabel';
import useSnackbar from '@core/hooks/useSnackbar';
import { RoleName } from '@core/types';
import Actions from './Actions';
import { FormProps } from './FormProps';
import { inputs, dropdownOptions } from './InputValues';
import makeValidationSchema from './formValidation';
import useSx from './sx';
import useFormState from './useFormState';

const Form = ({ role }: FormProps) => {
  const { initialValues, updateUser, isLoading } = useFormState();
  const showSnackbar = useSnackbar();
  const sx = useSx();
  const validationSchema = React.useMemo(() => makeValidationSchema(role), [role]);

  const handleUpdateUser = async (values: typeof initialValues) => {
    await updateUser(values);

    showSnackbar({
      message: 'Perfil actualizado con éxito.',
      title: 'Información actualizada',
      type: 'success',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleUpdateUser}
    >
      {({
        values: { gender, birthDate, ...updatedValues },
        handleSubmit,
        handleChange,
        errors,
        resetForm,
        setFieldValue,
      }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box onSubmit={handleSubmit} component="form" sx={sx.root}>
            {(Object.keys(updatedValues) as Array<keyof typeof updatedValues>).map((input) => (
              <InputWithLabel
                key={input}
                label={inputs[input].label}
                name={input}
                onChange={handleChange}
                value={updatedValues[input]}
                placeholder={inputs[input].placeholder}
                required={!!inputs[input].required}
                error={!!errors[input]}
                helperText={errors[input]}
                startAdornment={inputs[input].startAdornament}
                disabled={inputs[input].disabled}
                disableUnderline
              />
            ))}
            <DropdownWithLabel
              label={inputs.gender.label}
              name={inputs.gender.name}
              onChange={handleChange}
              value={gender}
              placeholder={inputs.gender.placeholder}
              required={!!inputs.gender.required}
              error={!!errors.gender}
              helperText={errors.gender}
              options={dropdownOptions}
              variant="filled"
            />
            {role === RoleName.Patient && (
              <DatePickerWithLabel
                label={inputs.birthDate.label}
                value={dayjs(birthDate)}
                onChange={(date: Dayjs | null) => {
                  const isValidDate = date && !isNaN(date.toDate().getTime());
                  if (isValidDate) setFieldValue('birthDate', date);
                }}
                maxDate={dayjs()}
                error={!!errors.birthDate}
                helperText={errors.birthDate}
              />
            )}
            <Actions resetForm={resetForm} isLoading={isLoading} />
          </Box>
        </LocalizationProvider>
      )}
    </Formik>
  );
};

export default Form;
