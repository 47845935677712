import { useState } from 'react'
import useSnackbar from '@core/hooks/useSnackbar';
import useLandingContext from '../../../hooks/useLandingContext';
import useUpdatePriority from '../../../hooks/useUpdatePriority';


const useFormState = () => {
    const { selectedStudy, modalState, setModalState } = useLandingContext()
    const showSnackbar = useSnackbar()
    const { updateStudyPriorityAsync, isUploading: isLoading } = useUpdatePriority()
    const [priority, setPriority] = useState(false);
    const [filters, setFilters] = useState({
        Role: 'Radiologist',
        Search: '',
        Order: 'ASC',
        pageSize: '100'
      })

    return {
        selectedStudy,
        modalState,
        setModalState,
        showSnackbar,
        updateStudyPriorityAsync,
        priority,
        setPriority,
        isLoading,
        filters
    }
}

export default useFormState