import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useProfile from "@core/hooks/useProfile"
import useWidth from "@core/hooks/useWidth"
import { RoleName } from "@core/types"
import useFilters from "../hooks/useFilters"
import useGetTemplate from "../hooks/useGetTemplates"

const useTemplatesState = () => {
  const navigate = useNavigate()
  const { role, hasAdminPermissions } = useProfile()
  const [ filters, setFilters, removeFilter ] = useFilters()
  const { templates } = useGetTemplate(filters)
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;

  useEffect(() => {
    if(role !== RoleName.Radiologist && role !== RoleName.System && !hasAdminPermissions) {
      navigate('/')
    }
  }, [role, navigate, hasAdminPermissions])
  

  return {
    templates,
    filters,
    setFilters,
    removeFilter,
    deviceWidth
  }
}

export default useTemplatesState