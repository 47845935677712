import React, { useContext } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Box } from '@mui/material';
import { ThemeContext } from '@core/providers/ThemeProvider';
import { themeDetailsMap } from '@core/utils/generateThemeDetailsMap';
import transformToBarChartData from 'app/features/subscription/utils/transformToBarChartData';
import { BarChartComponentProps } from './BarChartComponentProps';

const BarChartComponent: React.FC<BarChartComponentProps> = ({ styles, subscriptionDetails }) => {
  const { combinedThemeName } = useContext(ThemeContext);
  
  const barChartData = transformToBarChartData(subscriptionDetails);

  return (
    <Box sx={styles.chartBox}>
      <ResponsiveContainer width="100%" height={400}>
        <RechartsBarChart
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, subscriptionDetails.usage.totalStudiesCount]} />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="utilizado"
            fill={themeDetailsMap[combinedThemeName as keyof typeof themeDetailsMap]?.primaryColor}
          />
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarChartComponent;
