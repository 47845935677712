import React from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import ActionButton from '@components/Button/ActionButton'
import SearchInput from '@components/SearchInput'
import useFilters from '../../hooks/useFilters'
import useSx from './sx'

const Actions = () => {
  const navigate = useNavigate()
  const sx = useSx()
  const [filters, setFilter, removeFilter] = useFilters()
  
  const onTemplateAdd = () => {
    navigate(`/templates/add`)
  }

  return (
    <Box sx={sx.root}>
        <SearchInput 
          query="Search"
          filters={filters}
          setFilter={setFilter}
          removeFilter={removeFilter}
          placeholder='Buscar'
          sx={{
            width: {xs: '100%', md: '40%'}
          }}
        />
        <ActionButton 
          onClick={onTemplateAdd} 
          icon={<AddIcon />}
          text="Crear nueva plantilla"
          color="primary"
          variant="contained"
        />
    </Box>
  )
}

export default Actions