import React, { RefObject, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { Editor } from 'roosterjs-editor-core';
import sanitizeHtml from 'sanitize-html';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import FormDialogProps, { InitialValues } from './FormDialogProps';
import TemplateTagDropdown from './TemplateTagDropdown';
import TextEditor from './TextEdtior';
import validationSchema from './formValidation';
import useSx from './sx';

const FormDialog = ({
  onDelete,
  onAddNew,
  onSave,
  initialValues,
  canUserEditTemplate,
  title,
}: FormDialogProps) => {
  const sx = useSx();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const editor = useRef<Editor>(null);
  const navigate = useNavigate();
  const { hasAdminPermissions } = useProfile();

  const defaultSubmit = () => {};

  async function validateEditorContent(
    editorProp: RefObject<Editor>,
    validateForm: (values?: any) => Promise<FormikErrors<InitialValues>>,
    setErrors: (errors: FormikErrors<InitialValues>) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<InitialValues>>,
    values: InitialValues,
    onSubmit: (updatedValues: InitialValues) => void,
  ) {
    const content = editorProp.current?.getContent();
    if (content !== undefined) {
      const sanitizedHTML = sanitizeHtml(content, {
        allowedTags: [],
      });

      const newErrors = await validateForm();

      if (sanitizedHTML.length < 1) {
        setErrors({ ...newErrors, content: 'Por favor introduzca el contenido' });
        return;
      }

      await setFieldValue('content', content);
      onSubmit({ ...values, content: content });
    }
  }

  return (
    <>
      <Box sx={sx.tabsContainer}>
        <Tabs value={'no-value'} onChange={() => {}}>
          <Tab className="tab" value="no-value" label={title} />
        </Tabs>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formikRef}
        onSubmit={onSave || defaultSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          resetForm,
          setErrors,
          validateForm,
        }) => (
          <Box onSubmit={handleSubmit} sx={sx.root} className="form" component="form">
            <Box display="flex" alignItems="center" justifyContent="space-between" gap="1rem">
              <TextField
                disabled={!canUserEditTemplate}
                placeholder="Título"
                name="templateName"
                value={values.templateName}
                onChange={handleChange}
                error={!!errors.templateName}
                fullWidth
                helperText={errors.templateName}
                variant="standard"
                sx={sx.input}
              />
              <TextField
                disabled={!canUserEditTemplate}
                placeholder="Categoría"
                name="folderName"
                value={values.folderName}
                onChange={handleChange}
                error={!!errors.folderName}
                fullWidth
                helperText={errors.folderName}
                variant="standard"
                sx={sx.input}
              />
            </Box>
            <TemplateTagDropdown editor={editor} />
            <TextEditor
              initialContent={values.content}
              error={!!errors.content}
              helperText={errors.content}
              editor={editor}
            />
            <If condition={hasAdminPermissions && canUserEditTemplate}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.organizationWide}
                    onChange={(e) => {
                      setFieldValue('organizationWide', e.target.checked);
                    }}
                  />
                }
                label="Mostrar a toda la organización"
              />
            </If>
            <Box marginTop="1rem" display="flex" alignItems="center" gap="10px">
              <If condition={!!onDelete && canUserEditTemplate}>
                <ActionButton
                  sx={{ marginRight: 'auto' }}
                  text="Eliminar"
                  variant="outlined"
                  color="error"
                  onClick={onDelete}
                />
              </If>
              <If condition={canUserEditTemplate}>
                <ActionButton
                  sx={{ marginLeft: 'auto' }}
                  text="Cancelar"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    resetForm();
                    navigate('/templates');
                  }}
                />
              </If>

              <If condition={!!onAddNew}>
                <ActionButton
                  sx={{ marginLeft: !canUserEditTemplate ? 'auto' : '0' }}
                  text="Guardar como nuevo"
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    await validateEditorContent(
                      editor,
                      validateForm,
                      setErrors,
                      setFieldValue,
                      values,
                      onAddNew!,
                    );
                  }}
                />
              </If>
              <If condition={!!onSave && canUserEditTemplate}>
                <ActionButton
                  text="Guardar"
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await validateEditorContent(
                      editor,
                      validateForm,
                      setErrors,
                      setFieldValue,
                      values,
                      () => {
                        formikRef.current?.handleSubmit();
                      },
                    );
                  }}
                />
              </If>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default FormDialog;
