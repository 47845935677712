import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import If from '@components/If';
import { formatNumberAsCurrency } from '@core/utils/formatNumberAsCurrerncy';
import parseProductPlanName from 'app/features/subscription/utils/parseProductPlanName';
import { SubscriptionGeneralProps } from '../SubscriptionGeneralProps';
import Header from './Header/Header';
import TransferAccountModal from './TransferAccountModal/TransferAccountModal';
import useSubscriptionDetailState from './useSubscriptionDetailState';

const SubscriptionDetail: React.FC<SubscriptionGeneralProps> = ({ subscriptionDetails }) => {
  const {
    styles,
    isModalOpen,
    setModalOpen,
    adicionalValue,
    userPlan,
    nextPlan
  } = useSubscriptionDetailState(subscriptionDetails);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const showNextProductPlan = !!userPlan?.nextProductPlanId && userPlan?.nextProductPlanId !== userPlan?.plan?.productPlanId;

  return (
    <>
      <Header onTransferAccountClick={handleOpenModal} />
      <Box sx={styles.subscriptionDetailContainer}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{`Evodicom ${userPlan?.plan?.planName}`}</TableCell>
              <If condition={false}>
                <TableCell>{parseProductPlanName(userPlan?.nextProductPlanId)}</TableCell>
              </If>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell><strong>Estado de la suscripción</strong></TableCell>
              <TableCell>{userPlan?.status === "Active" ? 'Activa' : 'Inactiva'}</TableCell>
              <If condition={false}>
                <TableCell>Pendiente</TableCell>
              </If>
            </TableRow>
            <TableRow>
              <TableCell><strong>Detalles de la facturación</strong></TableCell>
              <TableCell>{userPlan?.renewalPeriod === 'Monthly' ? `MX${formatNumberAsCurrency(userPlan?.settings?.monthlyPrice)} / mes` : `MX${formatNumberAsCurrency(userPlan?.settings?.yearlyPrice)} / anual`}</TableCell>
              <If condition={false}>
                <TableCell>{userPlan?.renewalPeriod === 'Monthly' ? `MX${formatNumberAsCurrency(nextPlan?.settings?.monthlyPrice)} / mes` : `MX${formatNumberAsCurrency(nextPlan?.settings?.yearlyPrice)} / anual`}</TableCell>
              </If>
            </TableRow>
            <TableRow>
              <TableCell><strong>Adicionales de este período</strong></TableCell>
              <TableCell>{`${adicionalValue} ( MX${formatNumberAsCurrency(adicionalValue * userPlan?.settings?.priceAdditionalStudy)} )`}</TableCell>
              <If condition={false}>
                <TableCell>{`MX${formatNumberAsCurrency(nextPlan?.settings?.priceAdditionalStudy)} / estudio adicional`}</TableCell>
              </If>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <TransferAccountModal
        open={isModalOpen}
        onConfirm={handleCloseModal} 
        onCancel={handleCloseModal}
      />
    </>
  );
};

export default SubscriptionDetail;
