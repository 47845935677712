import React from 'react';
import { AccountCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { SubscriptionsDetails } from '../../SubscriptionGeneralProps';
import { UserChartComponentProps } from './UserChartProps';

const UserChartComponent: React.FC<UserChartComponentProps> = ({ styles, subscriptionDetails }) => {

  const transformToUserChartData = ({ usage, userPlan }: SubscriptionsDetails) => {
    const { usersCount } = usage;
    const { settings } = userPlan;
  
    const radiologistLabel = 'M. Radiólogos';
    const radiologistValue = `${usersCount?.Radiologist || 0} / ${settings?.usersIncluded?.radiologist}`;
  
    const technicianLabel = 'Técnicos';
    const technicianValue = `${usersCount?.Technician || 0} / ${settings?.usersIncluded?.technitians}`;
  
    const referrerLabel = 'Médicos Referentes';
    const referrerValue = `${usersCount?.Referrer || 0} / ${settings?.usersIncluded?.referrers}`;
  
    return [
      { label: radiologistLabel, value: radiologistValue },
      { label: technicianLabel, value: technicianValue },
      { label: referrerLabel, value: referrerValue },
    ];
  };

  const userChartData = transformToUserChartData(subscriptionDetails);
  
  return (
    <Box sx={styles.chartBox}>
      <AccountCircle sx={styles.userIcon} />
      <Typography variant="subtitle1" fontWeight={600} gutterBottom>
        Usuarios
      </Typography>
      <Box width="75%">
        {userChartData.map((item) => (
          <Box key={item.label} sx={styles.statsContainer}>
            <Typography variant="body1">{item.label}</Typography>
            <Box sx={styles.statsBox}>
              <Typography variant="body1" color="white">
                {item.value}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserChartComponent;
