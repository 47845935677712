import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import AppAuthenticationTemplate from '@components/AppAuthenticationTemplate';
import SettingsLayout from '@components/Layout/SettingsLayout';
import useIsAuthenticated from '@core/hooks/useIsAuthenticated';
import { hideStartLoader } from '@core/utils/startLoader';

const SettingsRoot = () => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    hideStartLoader({ isAuthenticated: isAuthenticated ?? false });
  }, [isAuthenticated]);

  return (
    <AppAuthenticationTemplate>
      <SettingsLayout>
        <Suspense fallback={<Backdrop open>Cargando</Backdrop>}>
          <Outlet />
        </Suspense>
      </SettingsLayout>
    </AppAuthenticationTemplate>
  );
};

export default SettingsRoot;
