import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as GET_TEMPLATE_QUERY_KEY } from '../../hooks/useGetTemplates';
import { removeTemplate } from '../api/removeTemplate';

const useRemoveTemplate = () => {
  const queryClient = useQueryClient()

  const { isLoading: isUploading, mutateAsync: asyncDeleteTemplate } = useMutation({
    mutationFn: (templateId: string) => removeTemplate(templateId),
    mutationKey: ["RemoveTemplate"],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TEMPLATE_QUERY_KEY])
    }
 });

  return { isUploading, removeTemplate: asyncDeleteTemplate };
};

export default useRemoveTemplate;