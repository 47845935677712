import React from 'react';
import { Card } from "@mui/material";
import { ContainerCardProps } from './ContainerCardProps';
import useSx from './sx';

const ContainerCard = ({ children }: ContainerCardProps) => {
  const sx = useSx();
  return (
    <Card sx={sx.root}>
      {children}
    </Card>
  );
}

export default ContainerCard;
