import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Template = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 20">
      <path className="cls-1" d="m10,0s0,0,0,0H2C1.45,0,.98.2.59.59c-.39.39-.59.86-.59,1.41v16c0,.55.2,1.02.59,1.41.39.39.86.59,1.41.59h12c.55,0,1.02-.2,1.41-.59s.59-.86.59-1.41V6s0,0,0,0L10,0Zm-.19,6.2s0-5.75,0-5.75c0,0,0,0,0,0l5.84,5.81m-1.86,13.15H2.01c-.79,0-1.42-.64-1.42-1.43l.04-16.06c0-.85.69-1.54,1.55-1.54h7.17s0,.01,0,.01v5.34c0,.51.41.93.92.93h5.25s0,0,0,0l-.1,11.12c0,.9-.74,1.62-1.63,1.62Z"/>
      <g>
        <path className="cls-1" d="m9.46,9.53c.26.15.5.29.77.44-.29.5-.57,1-.86,1.5-.61,1.05-1.21,2.1-1.82,3.14-.06.1-.16.19-.25.26-.2.13-.4.25-.64.39,0-.47-.05-.9.21-1.32.85-1.39,1.65-2.81,2.47-4.22.03-.06.07-.11.12-.18Z"/>
        <path className="cls-1" d="m9.97,8.7c.27.16.52.3.78.46-.1.18-.2.34-.3.52-.26-.15-.51-.29-.78-.44.1-.17.19-.34.3-.53Z"/>
      </g>
    </SvgIcon>
  );
};

export default Template;
