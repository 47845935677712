import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationTemplateImage } from '@core/api/getOrganizationTemplateImage';

export type UseOrganizationTemplateImageResult = Omit<UseQueryResult<any, any>, 'data'> & {
  renderedImage: Blob | null;
};

export const ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY = 'OrganizationTemplateImage';

const useOrganizationTemplateImage = (templateId: string): UseOrganizationTemplateImageResult => {
  const isEnabled = Boolean(templateId);
  const { data: renderedImage = null, ...rest } = useQuery({
    queryFn: () => getOrganizationTemplateImage(templateId),
    queryKey: [ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY, templateId],
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    cacheTime: 1000 * 60 * 60 * 24 * 1.5, // 36 hours
    enabled: isEnabled,
  });

  return { renderedImage, ...rest };
};

export default useOrganizationTemplateImage;
