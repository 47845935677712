import React, { useEffect, useState, RefObject } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import { ReportDocument } from 'app/features/reports/add/components/Form/documentTypes';
import useUploadReport from './useUploadReport';

const useViewerSideReportFunctionality = ({
  studyInstanceUid,
  viewerRef,
  reportRef,
}: {
  studyInstanceUid: string;
  viewerRef: RefObject<HTMLDivElement>;
  reportRef: RefObject<HTMLDivElement>;
}) => {
  const [showSideReport, setShowSideReport] = useState(false);
  const showSnackbar = useSnackbar();


  const { uploadReport, isUploading } = useUploadReport(studyInstanceUid);

  const handleOpenSideReport = () => {
    const event = new CustomEvent('evodicom.toggleSidePanel');
    document.dispatchEvent(event);
    const tooltipOpen = document.querySelector('.tooltip-toolbar-overlay') as HTMLDivElement;
    if(tooltipOpen){
      tooltipOpen.style.display = 'none';
    }
    setShowSideReport(true);
  };

  const handleCancelSideReport = () => {
    const event = new CustomEvent('evodicom.toggleSidePanel');
    document.dispatchEvent(event);
    if (viewerRef.current) {
      viewerRef.current.style.width = '100vw';
    }
    setShowSideReport(false);
  };

  const handleUploadReport = async (report: ReportDocument) => {
    if (!studyInstanceUid) return;
    try {
      const response = await uploadReport(report);

      if (response.success) {
        window?.location?.reload?.();
        showSnackbar({
          type: 'success',
          title: 'Reporte creado',
          message: 'El reporte se ha creado.',
        });
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible crear el reporte.',
      });
      console.log({ response });
    } catch (errorr: any) {
      console.log('Error', errorr);
    }
  };

  useEffect(() => {
    const BORDER_SIZE = 4;
    const viewer = viewerRef.current;
    const report = reportRef.current;
    let mPos: any;

    function resize(e: any) {
      if (viewer) {
        const dx = e.x - mPos;
        mPos = e.x;

        viewer.style.width = parseInt(getComputedStyle(viewer, '').width) + dx + 'px';

        if (report) {
          report.style.width = `calc(100vw - ${viewer.style.width})`;
          report.style.minWidth = `calc(100vw - ${viewer.style.width})`;
        }
      }
    }

    function onMouseDownHandler(e: any) {
      if (viewer) {
        const rect = viewer.getBoundingClientRect();
        if (e.clientX > rect.right - BORDER_SIZE) {
          mPos = e.x;
          document.addEventListener('mousemove', resize, false);
        }
      }
    }

    function onMouseUpHandler() {
      document.removeEventListener('mousemove', resize, false);
    }

    if (showSideReport) {
      viewer?.addEventListener('mousedown', onMouseDownHandler, false);
      document.addEventListener('mouseup', onMouseUpHandler, false);
    }

    return () => {
      if (viewer) {
        document.removeEventListener('mouseup', onMouseUpHandler);
        document.removeEventListener('mousemove', resize);
        viewer.removeEventListener('mousedown', onMouseDownHandler);
      }
    };
  }, [showSideReport]);

  return {
    showSideReport,
    handleUploadReport,
    handleCancelSideReport,
    setShowSideReport,
    handleOpenSideReport,
    isUploading,
  };
};

export default useViewerSideReportFunctionality;
