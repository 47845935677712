import React from 'react';
import Typography from '@mui/material/Typography';
import { Gender, genderNameMap } from '@core/types/Gender';
import { User } from '@core/types/User';

type InputValue = {
  label: string;
  name: string;
  placeholder: string;
  required?: boolean;
  startAdornament?: React.ReactNode;
  disabled?: boolean;
};

export type FormKeys = Exclude<
  keyof User,
  'userUid' | 'role' | 'isAdmin' | 'isOwner' | 'profilePhoto' | 'permissionRules'
>;

export const inputs: Record<FormKeys, InputValue> = {
  givenName: {
    label: 'Nombre',
    name: 'givenName',
    placeholder: 'Ingresa tu nombre',
    required: true,
  },
  surname: {
    label: 'Apellido',
    name: 'surname',
    placeholder: 'Ingresa tu apellido',
    required: true,
  },
  gender: {
    label: 'Género',
    name: 'gender',
    placeholder: 'Selecciona tu género',
  },
  emailAddress: {
    label: 'Correo',
    name: 'emailAddress',
    placeholder: 'Ingresa tu correo electrónico',
    required: true,
    disabled: true,
  },
  phoneNumber: {
    label: 'Celular',
    name: 'phone',
    placeholder: 'Ingresa tu número de celular',
    startAdornament: <Typography sx={{ marginRight: '0.5rem' }}>+52</Typography>,
  },
  birthDate: {
    label: 'Fecha de Nacimiento',
    name: 'birthDate',
    placeholder: '',
  },
};

type GenderDropdownOption = {
  value: Gender;
  label: string;
};

export const dropdownOptions: GenderDropdownOption[] = [
  {
    value: 'Unknown',
    label: genderNameMap.get('Unknown')!,
  },
  {
    value: 'Female',
    label: genderNameMap.get('Female')!,
  },
  {
    value: 'Male',
    label: genderNameMap.get('Male')!,
  },
];
