import React from 'react'
import Container from '@mui/material/Container'
import If from '@components/If';
import Title from '@components/Title';
import Actions from '../components/Actions';
import Pagination from '../components/Pagination';
import Table from '../components/Table';
import useTemplatesState from './useTemplatesState';

const Templates = () => {
  const { 
    templates,
    setFilters,
    filters,
    deviceWidth
  } = useTemplatesState()
  

  const onPaginationChange = (e: any, value: number) => {
    setFilters('Page', value.toString())
  }

  return (
    <Container sx={{ margin: 0 }} maxWidth={deviceWidth} disableGutters>
      <Title text='Plantillas' />
      <Actions />
      <Table templates={templates?.items || []} />
      <If condition={(templates?.page || 0) > 1}>
        <Pagination page={Number(filters?.Page || 1)} onChange={onPaginationChange} count={templates?.pageCount || 0} />
      </If>
    </Container>
  )
}

export default Templates