import React, { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import UserAutoComplete from '@components/UserAutoComplete';
import AssignedRadiologists from '../AssignedRadiologists';
import { FormPropsWithNotification } from './FormProps';
import useFormState from './useFormState';

const Form = ({
  open = false,
  onClose,
  studyInstanceUid,
  handleSendNotification,
  isSendingNotification,
}: FormPropsWithNotification) => {
  const {
    filters,
    assignRadiologistAsync,
    setFilters,
    showSnackbar,
    revokeRadiologistAccess,
    selectedRadiologist,
    assignedRadiologists,
    setSelectedRadiologist,
    users,
    setSelectedRadiologistInput,
    selectedRadiologistInput,
    isLoading,
  } = useFormState({ isOpen: open, studyInstanceUid });
  const isSubmittingLoading = isLoading || isSendingNotification;

  const handleRadiologistChange = (value: string): void => {
    if (!!value) {
      setFilters((prev) => ({
        ...prev,
        Search: value,
      }));
    }
  };

  const handleChangeDebounced = useMemo(
    () => debounce(handleRadiologistChange, 300),
    // we are creating a closure when using debounce thus we
    // need to make filters a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  const handleAssignRadiologist = async () => {
    const response = await assignRadiologistAsync({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedRadiologistInput?.userUid || '',
    });
    if (!response.success) {
      showSnackbar({
        message: response.error || 'No fue posible asignar al médico radiólogo.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }
    const userSelected = users?.items?.find(
      (item) => item.userUid === selectedRadiologistInput?.userUid,
    );
    await handleSendNotification(studyInstanceUid, userSelected);
    showSnackbar({
      message: 'Médico radiólogo asignado con éxito.',
      title: 'Médico asignado',
      type: 'success',
    });
  };

  const handleRevokeAccess = async () => {
    const response = await revokeRadiologistAccess({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedRadiologist?.userUid || '',
    });
    if (response.studyInstanceUid) {
      showSnackbar({
        message: 'Acceso revocado al estudio médico.',
        type: 'success',
        title: 'Acceso cancelado',
      });
      return;
    }

    showSnackbar({
      message: 'No se pudo revocar acceso al estudio médico. Por favor, intenta de nuevo.',
      type: 'error',
      title: 'Ocurrió un error',
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <AssignedRadiologists
        assignedRadiologist={assignedRadiologists?.authorizedUser}
        setSelectedRadiologist={setSelectedRadiologist}
        selectedRadiologist={selectedRadiologist}
      />
      <UserAutoComplete
        searchRoles="Radiologist"
        onUserSelect={(value) => {
          setSelectedRadiologistInput(value);
        }}
        placeholder="Seleccionar médico radiólogo"
      />
      <Box display="flex" gap="10px" justifyContent="flex-end">
        <If condition={selectedRadiologist}>
          <ActionButton
            text="Revocar Accesso"
            variant="contained"
            color="error"
            sx={{ marginRight: 'auto' }}
            onClick={handleRevokeAccess}
          />
        </If>
        <ActionButton text="Cancelar" variant="outlined" color="secondary" onClick={onClose} />
        <ActionButton
          text="Asignar Estudio"
          color="primary"
          variant="contained"
          onClick={handleAssignRadiologist}
          isLoading={isSubmittingLoading}
        />
      </Box>
    </Box>
  );
};

export default Form;
