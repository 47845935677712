import React, { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import DatePickerWithMobileOverride from '@components/DatePickerWithLabel/DatePickerWithMobileOverride';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import capitalizeText from '@core/utils/capitalizeText';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import useGetStudyAuthorization from 'app/features/read-only-study/hooks/useGetStudyAuthorization';
import BirthdateFormProps from './BirthdateFormProps';

dayjs.extend(utc);

const helperText = 'La fecha de nacimiento es requerida';

const ERROR_MESSAGE = `
    La fecha de nacimiento que proporcionaste no coincide con nuestros registros, 
    si crees que hay algún error, por favor contacta a nuestro personal
`;

const getTitle = (patientFirstName?: string, patientLastName?: string): string => {
  if (!patientFirstName && !patientLastName) {
    return 'Ingresa tu fecha de nacimiento';
  }

  const firstName = capitalizeText(patientFirstName);
  const lastName = capitalizeText(patientLastName);

  if (!firstName) {
    return `${lastName}, ingresa tu fecha de nacimiento`;
  }
  if (!lastName) {
    return `${firstName}, ingresa tu fecha de nacimiento`;
  }

  return `${firstName} ${lastName}, ingresa tu fecha de nacimiento`;
};

const validationSchema = Yup.object().shape({
  birthDate: Yup.date().required(helperText),
});
const initialValues = {
  birthDate: dayjs.utc(Date.now()),
};

type InitialValues = {
  birthDate: dayjs.Dayjs;
};

const BirthdateForm = ({ setIsCorrectDate, studyInfo }: BirthdateFormProps) => {
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const navigate = useNavigate();

  const { getStudyAuthorizationAsync, isAuthorizing } = useGetStudyAuthorization();
  const showSnackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const onSubmit = async (values: InitialValues) => {
    const response = await getStudyAuthorizationAsync({
      patientBirthdate: values.birthDate.toDate(),
      studyHash: searchParams.get('s') || '',
    });
    if (isCustomErroHandlerResponse(response)) {
      showSnackbar({
        message: ERROR_MESSAGE,
        title: 'Error',
        type: 'error',
        autoHideDuration: 1000000,
      });
      return;
    }
    setIsCorrectDate(true);
  };

  const handleRedirect = () => {
    if (studyInfo?.organizationId) {
      sessionStorage.setItem('organizationId', studyInfo?.organizationId);
    }
    navigate('/redirect');
  };

  const title = getTitle(studyInfo?.patientsFirstName, studyInfo?.patientsLastName);

  return (
    <ActionDialog
      onConfirm={() => {
        formikRef.current?.submitForm();
      }}
      isLoading={isAuthorizing}
      open={true}
      title={title}
      type="info"
      sx={{ '.link': { cursor: 'pointer' } }}
      hideCloseButton
    >
      <Typography>
        o,{' '}
        <Typography component="span" color="primary.main" className="link" onClick={handleRedirect}>
          inicia sesión
        </Typography>
      </Typography>
      <Formik
        innerRef={formikRef}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, setFieldValue }) => (
          <>
            <DatePickerWithMobileOverride
              label={''}
              value={values.birthDate}
              onChange={(date) => {
                setFieldValue('birthDate', date);
              }}
              showPlaceholders
            />
            <If condition={!!errors.birthDate}>
              <Typography textAlign="left" color="error.main">
                {helperText}
              </Typography>
            </If>
          </>
        )}
      </Formik>
    </ActionDialog>
  );
};

export default BirthdateForm;
