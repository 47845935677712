import React, { useState } from 'react'
import useSnackbar from '@core/hooks/useSnackbar';
import { User } from '@core/types/User';
import useUsers from 'app/features/users/hooks/useUsers';
import useAssignRadiologist from '../../../hooks/useAssignRadiologist';
import useGetAssignedRadiologists from '../../../hooks/useGetAssignedRadiologists';
import useRevokeRadiologistStudyAccess from '../../../hooks/useRevokeRadiologistStudyAccess';

const useFormState = ({ isOpen, studyInstanceUid }: {isOpen: boolean, studyInstanceUid: string}) => {
    const showSnackbar = useSnackbar()
    const { revokeRadiologistAccess } = useRevokeRadiologistStudyAccess()
    const { assignRadiologistAsync } = useAssignRadiologist()
    const { assignedRadiologists } = useGetAssignedRadiologists(studyInstanceUid || '', isOpen)
    const [selectedRadiologistInput, setSelectedRadiologistInput] = useState<null | User>(null)
    const [filters, setFilters] = useState({
      Role: 'Radiologist',
      Search: '',
      Order: 'ASC',
    })
    const [selectedRadiologist, setSelectedRadiologist] = useState<any>(null)
    const { users, isLoading } = useUsers({ filters })
  
    return {
      showSnackbar,
      revokeRadiologistAccess,
      assignRadiologistAsync,
      assignedRadiologists,
      selectedRadiologistInput,
      setSelectedRadiologistInput,
      setFilters,
      selectedRadiologist,
      setSelectedRadiologist,
      users,
      isLoading,
      filters,
    }
}

export default useFormState