import SxDef from '@core/types/SxDef';

const backgroundColor = (theme: any) =>
  `${
    theme.palette.mode === 'dark' ? theme.palette.shade?.[400] : theme.palette.shade?.[50]
  } !important`;

const useSx = (): SxDef => ({
  root: (theme) => ({
    '.ms-FocusZone': {
      backgroundColor: 'transparent !important',
      border: theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.23)' : 'none',
      borderColor: 'divider',
      borderBottomWidth: '1px',
      borderBottomColor: 'divider',
      borderBottomStyle: 'solid',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      overflow: 'hidden',
    },
    '.rooster': {
      border: theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.23)' : 'none',
      borderColor: 'divider',
      backgroundColor: backgroundColor,
      width: '100%',
      height: '100%',
      minHeight: '400px',
      outline: 'none',
      color: theme.palette.mode === 'light' ? '#000 !important' : '#969595 !important',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
      padding: '1rem'
    },
    '.ms-OverflowSet': {
      backgroundColor: backgroundColor,
    },
    '.rooster-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '.command-button': {
      backgroundColor: backgroundColor,
      paddingX: 0,
    },
    '.ms-OverflowSet-item:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#e9e8e8' : '#353434',
    },
    '.ms-OverflowSet-item:hover span': {
      color: 'rgb(0, 120, 212)',
      backgroundColor: theme.palette.mode === 'light' ? '#e9e8e8' : '#353434',
    },
    '.ms-OverflowSet-item:hover i': {
      color: 'rgb(0, 120, 212)',
    },
    '.ms-OverflowSet-overflowButton button': {
      backgroundColor: `${
        theme.palette.mode === 'dark' ? theme.palette.shade?.[400] : theme.palette.shade?.[50]
      } !important`,
    },
    '.ms-OverflowSet-overflowButton button:hover': {
      color: 'rgb(0, 120, 212)',
      backgroundColor: theme.palette.mode === 'light' ? '#e9e8e8' : '#353434',
    },
    '.ms-OverflowSet-overflowButton button:hover i': {
      color: 'rgb(0, 120, 212)',
      backgroundColor: theme.palette.mode === 'light' ? '#e9e8e8' : '#353434',
    },
    '.error-text': {
      color: theme.palette.error.main,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
      fontWeight: 400,
      fontSize: '0.65625rem',
      lineHeight: '1.66',
      textAlign: 'left',
      marginTop: '3px',
      marginRigt: '14px',
      marginBottom: 0,
      marginLeft: '14px',
    },
  }),
});

export default useSx;
