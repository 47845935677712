const parseProductPlanName = (nextProductPlanId: number | undefined) => {
  switch (nextProductPlanId) {
    case 1:
      return 'Evodicom Starter'
    case 2:
      return 'Evodicom Professional'
    case 3:
      return 'Evodicom Business'
    default:
      return ''
  }
}

export default parseProductPlanName;