import React, { useState } from 'react';
import { Formik } from 'formik';
import { Autocomplete, Chip, FormControlLabel, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import ViewerDialog from '@components/ViewerDialog';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import Actions from './Actions';
import FormProps, { FieldKeys } from './FormProps';
import PermissionsTable from './PermissionTable';
import fields from './fields';
import validationSchema from './formValidation';
import useSx from './sx';

const Form = ({
  initialValues,
  handleSubmit,
  onDelete,
  isLoading,
  permissionsArray,
}: FormProps) => {
  const sx = useSx();
  const { hasAdminPermissions } = useProfile();
  const [modalState, setModalState] = useState<null | 'ADD_PERMISSIONS'>();

  function closeModal() {
    setModalState(null);
  }

  function openPermissionModal() {
    setModalState('ADD_PERMISSIONS');
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit: formikSubmit, handleChange, errors, setFieldValue, resetForm }) => (
        <Box sx={sx.root} onSubmit={formikSubmit} component="form">
          {Object.entries(fields).map(([field, value], index) => {
            const FieldComponent = value.component;
            return (
              <FieldComponent
                key={index}
                name={field}
                defaultValue={values[field as FieldKeys]}
                label={value.label}
                required={value.required}
                options={value.options}
                onChange={handleChange}
                error={!!errors[field as FieldKeys]}
                helperText={errors[field as FieldKeys]}
                value={values[field as FieldKeys] || ''}
                variant="filled"
              />
            );
          })}
          <If
            condition={
              values.role !== '' && values.role !== RoleName.Referrer && hasAdminPermissions
            }
          >
            <>
              <Box sx={sx.permissions}>
                <FormControlLabel
                  control={<Switch checked={values.isAdmin} />}
                  label="Asignar rol de Administrador"
                  labelPlacement="start"
                  onChange={(_, checked) => {
                    setFieldValue('isAdmin', checked);
                  }}
                  sx={sx.control}
                />
                <ActionButton
                  color="primary"
                  variant="contained"
                  text="Agregar regla"
                  disabled={!!values.isAdmin}
                  onClick={openPermissionModal}
                />
              </Box>
              <If condition={!values.isAdmin}>
                <PermissionsTable
                  permissionArray={values.permissions || []}
                  removePermission={(permissionRuleId?: number) =>
                    setFieldValue(
                      'permissions',
                      values.permissions?.filter(
                        (permission) => permission?.permissionRuleId !== permissionRuleId,
                      ),
                    )
                  }
                />
              </If>
              <ViewerDialog
                open={modalState === 'ADD_PERMISSIONS'}
                title="Asignar permisos"
                onClose={() => {
                  setFieldValue('permissions', initialValues.permissions);
                  closeModal();
                }}
              >
                <Box sx={sx.modal}>
                  <Box sx={sx.chipContainer}>
                    {values.permissions?.map((perm) => (
                      <Chip
                        key={perm?.permissionRuleId}
                        label={perm?.name}
                        onDelete={() =>
                          setFieldValue(
                            'permissions',
                            values.permissions?.filter(
                              (permission) =>
                                permission?.permissionRuleId !== perm?.permissionRuleId,
                            ),
                          )
                        }
                      />
                    ))}
                  </Box>
                  <Autocomplete
                    filterSelectedOptions
                    multiple
                    renderInput={(params) => (
                      <TextField {...params} label="Asignar permisos" sx={sx.autocomplete} />
                    )}
                    renderTags={() => <></>}
                    value={values.permissions?.map((perm) => perm?.name)}
                    onChange={(e, data) =>
                      setFieldValue(
                        'permissions',
                        data.map((str) => {
                          return permissionsArray?.find((permission) => permission.name === str);
                        }),
                      )
                    }
                    options={permissionsArray?.map((elem) => elem.name)}
                  />
                  <Box sx={sx.modalButtons}>
                    <ActionButton
                      color="error"
                      variant="contained"
                      text="Cancelar"
                      onClick={() => {
                        setFieldValue('permissions', []);
                        closeModal();
                      }}
                      sx={sx.button}
                    />
                    <ActionButton
                      color="primary"
                      variant="contained"
                      text="Aceptar"
                      onClick={closeModal}
                      sx={sx.button}
                    />
                  </Box>
                </Box>
              </ViewerDialog>
            </>
          </If>
          <Actions isLoading={isLoading} onDelete={onDelete} resetForm={resetForm} />
        </Box>
      )}
    </Formik>
  );
};

export default Form;
