import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        marginTop: '1rem', 
        boxShadow: 'none',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        '.table-row': { 
            '&:first-child th': { 
                borderTop: '1px solid rgba(224, 224, 224, 0.2)' 
            },
            cursor: 'pointer',
            ':hover': {
                backgroundColor: (theme) => theme.palette?.shade?.[200]
            }
        },
        '.table-cell': {paddingLeft: '25px'},
        '.pdf-content-container': {
            display: 'flex', 
            alignItems: 'center', 
            gap: '5px'
        }
    },
});

export default useSx;
