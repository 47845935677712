import QRCode from 'qrcode';

const createQRCodeDiv = async (url: string) => {
  const anchorImage = document.createElement('a');
  anchorImage.target = '_blank';
  anchorImage.id = 'qr-code-link';
  const qrCode = document.createElement('canvas');
  const qrCodeBase64 = await QRCode.toDataURL(qrCode, url);
  const qrImage = document.createElement('img');
  qrImage.src = `${qrCodeBase64}`;
  qrImage.style.width = '100px';
  qrImage.style.height = '100px';

  anchorImage.style.right = '0%';
  anchorImage.style.position = 'absolute';
  anchorImage.style.bottom = '0%';
  anchorImage.appendChild(qrImage);

  return anchorImage;
};

export default createQRCodeDiv;
