import createDoctorNameParagraph from '../utils/createDoctorNameParagraph';
import createFooterDiv from '../utils/createFooterDiv';
import createProfessionalInfoParagraphs from '../utils/createProfessionalInfoParagraphs';
import createQRCodeDiv from '../utils/createQRCodeDiv';
import createSignatureImage from '../utils/createSignatureImage';
import { ButtonFactory } from './buttonRegistryFactory';

const createAddFooterRibbonButton: ButtonFactory = ({ studyInfo, userInfo }) => {
  return {
    key: 'buttonQRCode',
    unlocalizedText: 'Insert QR Code',
    iconName: 'InsertSignatureLine',
    onClick: async () => {
      const editors = document.getElementsByClassName('rooster');
      const lastEditor = editors.item(editors.length - 1);
      document.querySelectorAll('#qr-code-link')?.forEach((node) => node.remove());
      document.querySelectorAll('#footer-editor')?.forEach((node) => node.remove());
      //START: Creating QR CODE image
      const anchorImage = await createQRCodeDiv(studyInfo.shareUrl || '');

      lastEditor?.appendChild(anchorImage);

      const divFooter = createFooterDiv();

      //START: Creating signature image
      if (userInfo?.signature) {
        const image = createSignatureImage(userInfo.signature);
        divFooter.appendChild(image);
      }
      //END: Creating signature image

      //START: Creating fullname paragraph
      const professionalNameParagraph = createDoctorNameParagraph(userInfo.fullName || '');
      divFooter.appendChild(professionalNameParagraph);
      //END: Creating fullname paragraph

      //START: Creating professionalInfo paragraphs
      if (userInfo?.professionalInfo) {
        const professionalInfoParagraphs = createProfessionalInfoParagraphs(
          userInfo.professionalInfo,
        );
        professionalInfoParagraphs.forEach((paragraph) => {
          divFooter.appendChild(paragraph);
        });
      }
      lastEditor?.appendChild(divFooter);
    },
  };
};

export default createAddFooterRibbonButton;
