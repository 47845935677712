import React, { useMemo } from 'react';
import { RibbonButton, getButtons } from 'roosterjs-react';
import { isAllowedFont } from '@core/utils/fonts';
import { ButtonFactoryProps } from 'app/features/reports/add/buttons/buttonRegistryFactory';
import lineHeightSelector from 'app/features/reports/add/buttons/lineHeightSelector';

const filterFonts = (button: RibbonButton<string>) => {
  const fonts = (
    button.dropDownMenu?.items ? Object.entries(button.dropDownMenu.items) : []
  ).filter((fontEntry) => isAllowedFont(fontEntry[1]));

  if (fonts.length > 0) {
    button.dropDownMenu!.items = Object.fromEntries(fonts);
  }
};

const useGetFilteredButtons = (themeMode: string) => {
  const buttonProps: ButtonFactoryProps = {
    themeMode,
    userInfo: {},
    studyInfo: {},
    templates: [],
  };

  const buttons = getButtons() as unknown as RibbonButton<string>[];
  const lineHeightButton = lineHeightSelector(buttonProps);

  const fontDownIndex =
    buttons.findIndex((button) => button.key === 'buttonNameDecreaseFontSize') + 1;
  const buttonsToRemove = [
    'buttonNameQuote',
    'buttonNameInsertLink',
    'buttonNameRemoveLink',
    'buttonNameSubscript',
    'buttonNameStrikethrough',
    'buttonNameCode',
    'buttonNameLtr',
    'buttonNameRtl',
    'buttonNameInsertTable',
  ];

  const allButtons = [
    ...buttons.slice(0, fontDownIndex),
    lineHeightButton,
    ...buttons.slice(fontDownIndex),
  ].filter((button) => !buttonsToRemove.includes(button.key));

  const filteredButtons = useMemo(
    () =>
      allButtons.map((button) => {
        button.commandBarProperties = button.commandBarProperties || {};
        button.commandBarProperties!.className = 'command-button';

        if (button.key === 'buttonNameFont') {
          filterFonts(button);
        }

        if (button.dropDownMenu) {
          button.dropDownMenu.commandBarSubMenuProperties =
            button.dropDownMenu.commandBarSubMenuProperties || {};
          button.dropDownMenu.commandBarSubMenuProperties.className =
            themeMode === 'light'
              ? 'dropdown-menu dropdown-menu--light'
              : 'dropdown-menu dropdown-menu--dark';
        }

        return button;
      }) as unknown as RibbonButton<string>[],
    [themeMode, buttons],
  );

  return { filteredButtons };
};

export default useGetFilteredButtons;
