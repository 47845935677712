import React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ActionButton from '@components/Button/ActionButton';
import { UserItem } from '@core/types';
import useGetAssignedRadiologists from 'app/features/studies/hooks/useGetAssignedRadiologists';
import useUsers from 'app/features/users/hooks/useUsers';
import { FormPropsWithNotification } from './FormProps';
import useSx from './sx';
import useFormState from './useFormState';

const Form = ({ open, onClose, studyInstanceUid, studyInfo, handleSendNotification, isSendingNotification }: FormPropsWithNotification) => {
  const { showSnackbar, updateStudyPriorityAsync, priority, setPriority, isLoading, filters } = useFormState();
  const { assignedRadiologists } = useGetAssignedRadiologists(studyInstanceUid || '', true)
  const { users } = useUsers({filters, enabled: open})
  const sx = useSx();
  const isSubmittingLoading = isLoading || isSendingNotification;
  const assignedRadiologist = assignedRadiologists?.authorizedUser as UserItem | undefined;
  
  const handleUpdatePriority = async () => {
    const response = await updateStudyPriorityAsync({
      studyInstanceUid: studyInstanceUid || '',
      priority,
    });
    if (!response?.success) {
      showSnackbar({
        type: 'error',
        message: 'La prioridad no ha sido actualizada con éxito',
        title: 'Ocurrió un error',
      });
    }
    showSnackbar({
      type: 'success',
      message: 'La prioridad ha sido actualizada con éxito',
      title: 'Prioridad actualizada',
    });
    onClose?.();

    if (priority) {
      const userSelected = users?.items?.find(item => item.userUid === assignedRadiologist?.userUid)
      await handleSendNotification(studyInstanceUid, userSelected)
    }
  };

  return (
    <>
      <Box display="flex" gap="5px" alignItems="center">
        <FormControlLabel
          control={<Switch defaultChecked={studyInfo?.priority || false} />}
          label="Este estudio es de alta prioridad"
          onChange={(_, checked) => {
            setPriority(checked);
          }}
          sx={sx.label}
          labelPlacement="start"
        />
      </Box>
      <Box display="flex" gap="10px" justifyContent="flex-end">
        <ActionButton text="Cancelar" variant="outlined" color="secondary" onClick={onClose} />
        <ActionButton
          text="Aceptar"
          variant="contained"
          color="primary"
          onClick={handleUpdatePriority}
          isLoading={isSubmittingLoading}
        />
      </Box>
    </>
  );
};

export default Form;
