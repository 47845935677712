import { PageConfig } from '../components/Form/TextEditor/TextEditor';
import splitDomTree from './splitDomTree';

function splitPage(editorNode: Element, maxHeight: number, pageConfig: PageConfig): Node[] {
  if (editorNode.childNodes.length === 0) {
    return [];
  }

  // Crear un div para medir el contenido
  const canaryDiv = document.createElement('div');
  canaryDiv.style.fontFamily = pageConfig.fontFamily;
  canaryDiv.style.fontSize = `${pageConfig.fontSize}px`;
  canaryDiv.style.lineHeight = `${pageConfig.lineHeight}`;
  canaryDiv.style.width = `${
    pageConfig.size.width - pageConfig.padding.left - pageConfig.padding.right
  }px`;

  // Posicionar el canaryDiv para propósitos de debugging
  canaryDiv.style.position = 'fixed';
  canaryDiv.style.top = '200px';
  canaryDiv.style.left = '700px';

  document.body.appendChild(canaryDiv);

  try {
    const splittedNodes = splitDomTree(editorNode, canaryDiv, canaryDiv, maxHeight);

    if (splittedNodes.length > 0) {
      // Limpiar el editor
      editorNode.innerHTML = '';

      // Mover todos los hijos desde canaryDiv al editorNode
      while (canaryDiv.firstChild) {
        const child = canaryDiv.firstChild;
        canaryDiv.removeChild(child);
        editorNode.appendChild(child);
      }
    }

    return splittedNodes;
  } finally {
    // Asegurarse de eliminar el canaryDiv del DOM
    document.body.removeChild(canaryDiv);
  }
}

export default splitPage;
