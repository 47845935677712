import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as GET_USER_QUERY_KEY } from '@core/hooks/useGetUser';
import { User } from '@core/types/User';
import { QUERY_KEY as GET_USERS_QUERY_KEY } from '../../hooks/useUsers';
import { updateUser } from '../api/updateUser';

const useUpdateUser = (userUid: string | undefined) => {
  const queryClient = useQueryClient()

  const { isLoading, mutateAsync: asyncUpdateUser } = useMutation({
    mutationFn: (payload: User) => updateUser({userUid, ...payload}),
    mutationKey: ["UserUpdate", userUid],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USERS_QUERY_KEY])
      queryClient.invalidateQueries([GET_USER_QUERY_KEY])
    }
 });

  return { isLoading, updateUser: asyncUpdateUser };
};

export default useUpdateUser;
