import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import Section from '@components/Section';
import Title from '@components/Title';
import useSnackbar from '@core/hooks/useSnackbar';
import { RoleName } from '@core/types';
import { User } from '@core/types/User';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import Form from '../../add/components/Form';
import { InitialValues } from '../../add/components/Form/FormProps';
import FormContainer from '../../add/components/FormContainer';
import useEditUserState from './useEditUserState';

const AddUser = () => {
  const navigate = useNavigate();
  const { open, setOpen, user, updateUser, isUpdating, isDeleting, deleteUser, permissions } =
    useEditUserState();

  const userLastName = user.surname ? ` ${user.surname}` : '';
  const fullName = `${user.givenName}${userLastName}`;

  const showSnackbar = useSnackbar();

  const handleError = () => {
    showSnackbar({
      message: 'El usuario no ha podido ser actualizado.',
      title: 'Ocurrió un error',
      type: 'error',
    });
  };
  const handleSubmit = async (values: InitialValues) => {
    try {
      const permissionRuleIds =
        values.role === RoleName.Referrer || values.isAdmin
          ? []
          : values.permissions?.map((permission) => permission?.permissionRuleId);

      const payload = { ...values, permissionRuleIds };
      // Delete sensible information from request
      delete payload.permissions;
      // @ts-expect-error Form Type and User type are not exactly the same
      delete payload.permissionRules;

      const response = await updateUser(payload as User);
      if (isCustomErroHandlerResponse(response)) {
        throw new Error('No ha sido posible crear el usuario');
      }
      showSnackbar({
        message: 'El usuario ha sido actualizado con éxito.',
        title: 'Usuario actualizado',
        type: 'success',
      });
    } catch (err) {
      handleError();
    }
  };

  const onDeleteClick = () => {
    setOpen(true);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteUser(user?.userUid || '');
    if (response.statusCode) {
      showSnackbar({
        message: 'No ha sido posible eliminar este usuario.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }
    showSnackbar({
      message: 'El usuario ha sido removido con éxito.',
      title: 'Usuario eliminado',
      type: 'success',
    });
    navigate('/settings/users');
  };

  return (
    <>
      <Title text="Usuario" />
      <ContainerCard>
        <Section title="Editar Usuario" IconComponent={EditIcon} description="">
          <FormContainer>
            <Form
              handleSubmit={handleSubmit}
              initialValues={{
                ...user,
                permissions:
                  user?.permissionRules?.map((rule) =>
                    permissions?.find(
                      (permission) => permission.permissionRuleId === rule.permissionRuleId,
                    ),
                  ) || [],
              }}
              onDelete={onDeleteClick}
              isLoading={isUpdating}
              permissionsArray={permissions || []}
            />
          </FormContainer>
        </Section>
      </ContainerCard>
      <ActionDialog
        open={open}
        message={`Estás a punto de remover al usuario ${fullName} de la organización. Esta acción desvinculará el acceso y los privilegios asociados.`}
        title="Confirmar remoción del usuario"
        isLoading={isDeleting}
        onClose={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        onConfirm={onDeleteConfirm}
        type="delete"
      />
    </>
  );
};

export default AddUser;
