import React, { useCallback, useEffect } from 'react';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import If from '@components/If';
import SoftLoading from '@components/SoftLoading';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import { TranslatorButtonProps } from './TranslatorButtonProps';
import useTranslatorButtonState from './useTranslatorButtonState';

const TranslatorButton = ({ textEditorRef }: TranslatorButtonProps) => {
  const { postTranslateAsync, isTranslating, warningDialogOpen, setWarningDialogOpen } =
    useTranslatorButtonState();

  const replaceTemplateWithPlaceholder = (text: string) => {
    const placeholders: string[] = [];
    let placeholderIndex = 0;
    const replacedText = text.replace(/\{\{(.*?)\}\}/g, (match) => {
      const placeholder = `{{PLACEHOLDER_${placeholderIndex++}}}`;
      placeholders.push(match);
      return placeholder;
    });
    return { replacedText, placeholders };
  };

  const restorePlaceholderWithTemplate = (text: string, placeholders: string[]) => {
    return text.replace(/\{\{PLACEHOLDER_(\d+)\}\}/g, (match, index) => placeholders[index]);
  };

  const removeNodesToSkip = (doc: Document) => {
    // Directly remove elements with IDs 'footer-editor' and 'qr-code-link' from the document
    doc.querySelectorAll('#footer-editor, #qr-code-link').forEach((el) => el.remove());
  };

  const translateNode = async (node: Node, placeholders: string[]) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const { replacedText, placeholders: newPlaceholders } = replaceTemplateWithPlaceholder(
        node.textContent || '',
      );
      const translation = await postTranslateAsync(replacedText);
      node.textContent = restorePlaceholderWithTemplate(translation, newPlaceholders);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (const child of node.childNodes) {
        await translateNode(child, placeholders);
      }
    }
  };

  const translateText = async () => {
    try {
      const currentContent = textEditorRef.current?.getContent();
      const htmlStringToTranslate = currentContent?.join(' ') || '';
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlStringToTranslate, 'text/html');

      removeNodesToSkip(doc);

      await translateNode(doc.body, []);

      const serializer = new XMLSerializer();
      const translatedHTML = serializer.serializeToString(doc);

      if (textEditorRef.current) {
        textEditorRef.current.setHTMLTemplateContent(translatedHTML);
      }
    } catch (error) {
      console.error('Error translating text:', error);
    }
  };

  const handleShowWarningModal = () => {
    setWarningDialogOpen(true);
  };

  const onClickTranslator = useCallback(handleShowWarningModal, []);

  useEffect(() => {
    subscribe('evodicom.report.translate', onClickTranslator);

    return () => {
      unsubscribe('evodicom.report.translate', onClickTranslator);
    };
  }, [onClickTranslator]);

  return (
    <>
      <ActionDialog
        open={warningDialogOpen}
        message="Al traducir el reporte al inglés, se eliminará y perderá el contenido actual para ser reemplazado con la traducción"
        title="Actualizar Reporte"
        onClose={() => {
          setWarningDialogOpen(false);
        }}
        onConfirm={() => {
          setWarningDialogOpen(false);
          translateText();
        }}
        type="warning"
      />
      <If condition={isTranslating}>
        <SoftLoading isOpen={isTranslating} />
      </If>
    </>
  );
};

export default TranslatorButton;
