import { useSearchParams } from 'react-router-dom';
const FILTERS = ['Search', 'Page'] as const;
export type FilterName = (typeof FILTERS)[number];
export type SetFilterFn = (name: FilterName, value: string) => void;
export type RemoveFilterFn = (name: FilterName) => void;
export type SearchParams = Partial<{ [k in FilterName]: string }>;

const useFilters = (): [SearchParams, SetFilterFn, RemoveFilterFn] => {
  const [searchParams, setSearchParams] = useSearchParams({
        Page: '1'
    });

  const filters = FILTERS.reduce((paramsObj, param) => {
    let value: string | null = null;

    if ((value = searchParams.get(param)) !== null) {
      paramsObj[param] = value ?? undefined;
    }

    return paramsObj;
  }, {} as SearchParams);

  function setFilter(name: string, value: string) {
    const existing: Record<string, string> = {};

    for (const [key, param] of searchParams.entries()) {
      existing[key] = param;
    }

    setSearchParams({ ...existing, [name]: value });
  }

  function removeFilter(name: string) {
    const existing: Record<string, string> = {};

    for (const [key, param] of searchParams.entries()) {
      if (key === name) {
        continue;
      }

      existing[key] = param;
    }

    setSearchParams({ ...existing });
  }

  return [filters, setFilter, removeFilter];
};

export default useFilters;
