import React from 'react';
import Box from '@mui/material/Box';
import Title from '@components/Title';
import useSx from './sx';

const Header = () => {
  const sx = useSx();

  return (
    <Box sx={sx.root} display="flex" alignItems="center" marginBottom="16px">
      <Title text="Plantillas" />
    </Box>
  );
};

export default Header;
