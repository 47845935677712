import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import useSnackbar from '@core/hooks/useSnackbar';
import FormDialog from './FormDialog';
import { InitialValues } from './FormDialog/FormDialogProps';
import Header from './Header';
import useSx from './sx';
import useAddTemplateState from './useAddTemplateState';

const AddTemplate = () => {
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const { deviceWidth, addTemplate } = useAddTemplateState();
  const navigate = useNavigate();

  const onSave = async (values: InitialValues) => {
    const addTemplateResponse = await addTemplate({ ...values });
    if (!addTemplateResponse.success) {
      showSnackbar({
        message: 'La plantilla no ha sido guardada.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }

    showSnackbar({
      message: 'Plantilla configurada con éxito',
      title: 'Plantilla guardada',
      type: 'success',
    });

    navigate(`/templates/${addTemplateResponse.template.templateId}`);
  };

  return (
    <Container sx={sx.root} maxWidth={deviceWidth} disableGutters>
      <Header />
      <ContainerCard>
        <FormDialog
          title="Nueva plantilla"
          canUserEditTemplate
          onSave={onSave}
          initialValues={{
            templateName: '',
            content: '',
            folderName: '',
            organizationWide: false,
          }}
        />
      </ContainerCard>
    </Container>
  );
};

export default AddTemplate;
