import React, { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import { RoleName } from '@core/types';
import AssignedTechnician from '../AssignedTechnician';
import { FormPropsWithNotification } from './FormProps';
import useFormState from './useFormState';

const Form = ({
  open = false,
  onClose,
  role,
  studyInstanceUid,
  studyInfo,
  handleSendNotification,
  isSendingNotification,
}: FormPropsWithNotification) => {
  const {
    filters,
    assignTechnicianAsync,
    selectedTechnicianInput,
    setSelectedTechnicianInput,
    selectedTechnician,
    setFilters,
    showSnackbar,
    users,
    isLoading,
  } = useFormState({ isOpen: open, studyInstanceUid, studyInfo });
  const isSubmittingLoading = isLoading || isSendingNotification;

  const handleTechnicianChange = (value: string): void => {
    if (!!value) {
      setFilters((prev) => ({
        ...prev,
        Search: value,
      }));
    }
  };

  const handleChangeDebounced = useMemo(
    () => debounce(handleTechnicianChange, 300),
    // we are creating a closure when using debounce thus we
    // need to make filters a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  const handleAssignTechnician = async () => {
    const response = await assignTechnicianAsync({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedTechnicianInput?.userUid || '',
    });
    if (!response.success) {
      showSnackbar({
        message: response.error || 'No fue posible asignar al técnico radiólogo.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }
    const userSelected = users?.items?.find(
      (item) => item.userUid === selectedTechnicianInput?.userUid,
    );
    await handleSendNotification(studyInstanceUid, userSelected);
    showSnackbar({
      message: 'Técnico radiólogo asignado con éxito.',
      title: 'Técnico asignado',
      type: 'success',
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <AssignedTechnician assignedTechnician={selectedTechnician} />
      <If condition={!role || role !== RoleName.Patient}>
        <Autocomplete
          options={users?.items || []}
          renderOption={(props, option) => (
            <li {...props} key={option.userUid}>
              {option.fullName}
            </li>
          )}
          getOptionLabel={(option) => option.fullName}
          loading={isLoading}
          onChange={(_, value) => {
            setSelectedTechnicianInput(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={($e) => {
                handleChangeDebounced($e.target.value);
              }}
              fullWidth
              hiddenLabel
              sx={{
                '.MuiInputBase-root': {
                  paddingY: '0',
                  paddingLeft: '8px',
                },
              }}
              placeholder="Seleccionar técnico radiólogo"
            />
          )}
        />
        <Box display="flex" gap="10px" justifyContent="flex-end">
          <If condition={!!onClose}>
            <ActionButton text="Cancelar" variant="outlined" color="secondary" onClick={onClose} />
          </If>
          <ActionButton
            text="Asignar Estudio"
            color="primary"
            variant="contained"
            onClick={handleAssignTechnician}
            isLoading={isSubmittingLoading}
          />
        </Box>
      </If>
    </Box>
  );
};

export default Form;
