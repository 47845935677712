/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from 'react';
import { debounce } from 'lodash';
import * as roosterjs from 'roosterjs';
import { ContentEdit, ImageEdit } from 'roosterjs-editor-plugins';
import { Rooster } from 'roosterjs-react';
import { v4 as uuidv4 } from 'uuid';
import './editor.css';
import SoftLoading from '@components/SoftLoading';
import useProfile from '@core/hooks/useProfile';
import { useGetSignature } from 'app/features/users/edit/hooks/useSignature';
import {
  FOOTER_ELEMENT_SELECTOR,
  QRCODE_ELEMENT_SELECTOR,
} from '../../../constants/reportElements';
import useGetConclusionFuncionality from '../../../hooks/useGetConclusionFuncionality';
import applyEditorDivStyle from '../../../utils/applyEditorStyle';
import caretIsAtBeginningOfPage from '../../../utils/caretIsAtBeginningOfPage';
import caretIsAtEndOfPage from '../../../utils/caretIsAtTheEndOfPage';
import checkForValidFont from '../../../utils/checkForValidFont';
import createDoctorNameParagraph from '../../../utils/createDoctorNameParagraph';
import createFooterDiv from '../../../utils/createFooterDiv';
import createProfessionalInfoParagraphs from '../../../utils/createProfessionalInfoParagraphs';
import createQRCodeDiv from '../../../utils/createQRCodeDiv';
import createSignatureImage from '../../../utils/createSignatureImage';
import findEditorNode from '../../../utils/findEditorNode';
import findPageIndex from '../../../utils/findPageIndex';
import getCurrentCaretRange from '../../../utils/getCurrentCarretRange';
import getDistanceFromTopOfLastElement from '../../../utils/getDistanceFromTopOfLastElement';
import getLastTextNode from '../../../utils/getLastTextNode';
import getNodesPath from '../../../utils/getNodesPath';
import isPageContentNonEmpty from '../../../utils/isPageContentNonEmpty';
import navigateToCaretNode from '../../../utils/navigateToCaretNode';
import removeEmptyLeadingNodes from '../../../utils/removeEmptyLeadingNodes';
import setCaretAtEnd from '../../../utils/setCaretAtEnd';
import setCaretAtEndOfText from '../../../utils/setCaretAtEndOfText';
import setCaretAtBeginning from '../../../utils/setCaretAtTheBegining';
import splitPage from '../../../utils/splitPage';
import { TextEditorProps, TextEditorMethods } from '../documentTypes';
import UpdateStatePlugin from './UpdateStatePlugin';

const defaultFormat = {
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: '11pt',
  textColor: 'black',
  backgroundColor: 'rgba(0,0,0,0)',
};

export type PageConfig = {
  size: {
    width: number;
    height: number;
  };
  position: {
    x: number;
    y: number;
  };
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  fontFamily: string;
  fontSize: number;
  lineHeight: number;
};

interface DocumentPage {
  overflowChecker(): void;
  mutationObserver: MutationObserver;
  caretDirection: string | null;
  keyDirection: string | null;
}

export interface Caret {
  nodesPath: Array<number>;
  caretOffset: number;
  pageNumber: number;
}

interface EditorComponent {
  component: JSX.Element;
  componentKey: string;
}

function isCursorMovement(range: Range, pageContainerProp: HTMLDivElement): boolean {
  if (!range.collapsed) return false;

  const ancestorContainer = range.commonAncestorContainer;
  return pageContainerProp.contains(ancestorContainer) ?? false;
}

const TextEditor = forwardRef<TextEditorMethods, TextEditorProps>(function TextEditor(
  { backgroundUrl, createPluginsForEditor, handlePageChange, study, onMount },
  ref,
) {
  const pages = useRef<Array<DocumentPage>>([]);
  const [counter, setCounter] = useState(0);
  const editorComponents = useRef<Array<EditorComponent>>([]);
  const pageContainer = useRef<HTMLDivElement>(null);
  const [pagesContent, setPagesContent] = useState<Array<string>>([]);
  const editors = useRef<Array<roosterjs.Editor>>([]);
  const editorDivs = useRef<Array<HTMLDivElement>>([]);
  const currentPageIndex = useRef(0);
  const caretPosition = useRef<Caret | null>(null);
  const ignoreSelectionChanges = useRef(false);

  const { fullName } = useProfile();
  const { data } = useGetSignature();

  const isFooterLoaded = useRef(false);

  const { isGettinConclusion } = useGetConclusionFuncionality({ editorDivs: editorDivs.current });

  const [anchorImage, setAnchorImage] = useState(document.createElement('a'));

  const memoSignature = useMemo(() => {
    const divFooter = createFooterDiv();

    //START: Creating signature image
    if (data?.signature) {
      const image = createSignatureImage(data.signature);
      divFooter.appendChild(image);
    }
    //END: Creating signature image

    //START: Creating fullname paragraph
    const professionalNameParagraph = createDoctorNameParagraph(fullName || '');
    divFooter.appendChild(professionalNameParagraph);
    //END: Creating fullname paragraph

    //START: Creating professionalInfo paragraphs
    if (data?.professionalInfo) {
      const professionalInfoParagraphs = createProfessionalInfoParagraphs(data.professionalInfo);
      professionalInfoParagraphs.forEach((paragraph) => {
        divFooter.appendChild(paragraph);
      });
    }
    //END: Creating professionalInfo paragraphs
    return divFooter;
  }, [data?.signature, data?.professionalInfo, fullName]);

  useEffect(() => {
    // Here we keep the anchorImage on memory
    async function generateAnchorImage() {
      if (study?.shareUrl) {
        //START: Creating QR CODE image
        const image = await createQRCodeDiv(study?.shareUrl || '');

        setAnchorImage(image);
        //END: Creating QR CODE image
      }
    }
    generateAnchorImage();
  }, [study?.shareUrl]);

  useEffect(() => {
    editorDivs.current.forEach((editorDiv) => {
      if (editorDiv.parentElement) {
        editorDiv.parentElement.style.backgroundImage = `url(${backgroundUrl})`;
      }
    });
  }, [backgroundUrl]);

  useEffect(() => {
    const lastEditorDiv = editorDivs.current[editorDivs.current.length - 1];
    if (lastEditorDiv) {
      const footer = lastEditorDiv.querySelector(FOOTER_ELEMENT_SELECTOR);
      const qrCode = lastEditorDiv.querySelector(QRCODE_ELEMENT_SELECTOR);
      let foundFooter = false;
      let foundQRCode = false;
      lastEditorDiv.childNodes.forEach((node) => {
        if (node.isEqualNode(footer)) {
          foundFooter = true;
        }

        if (node.isEqualNode(qrCode)) {
          foundQRCode = true;
        }
      });

      if (!foundQRCode && qrCode) {
        const temporaryQRCode = qrCode?.cloneNode(true);
        lastEditorDiv.appendChild(temporaryQRCode!);
      }

      if (!foundFooter && footer) {
        const temporaryFooterClone = footer?.cloneNode(true);
        lastEditorDiv.appendChild(temporaryFooterClone!);
      }

      document.querySelectorAll(QRCODE_ELEMENT_SELECTOR).forEach((node) => {
        const parent = node.parentNode as HTMLElement;

        if (parent.className !== 'rooster editor') {
          node.remove();
        }
      });

      document.querySelectorAll(FOOTER_ELEMENT_SELECTOR).forEach((node) => {
        const parent = node.parentNode as HTMLElement;

        if (parent.className !== 'rooster editor') {
          node.remove();
        }
      });
    }
  });

  useEffect(() => {
    const addFooter = async () => {
      const newLastPage = editorDivs.current[editorDivs.current.length - 1];
      const oldLastPage = editorDivs.current[editorDivs.current.length - 2];
      if (newLastPage && (data || study?.shareUrl)) {
        if (oldLastPage) {
          const tempoLastElement = oldLastPage;
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = tempoLastElement.innerHTML;
          tempDiv.querySelector(FOOTER_ELEMENT_SELECTOR)?.remove();
          tempDiv.querySelector(QRCODE_ELEMENT_SELECTOR)?.remove();
          tempoLastElement.innerHTML = tempDiv.innerHTML;
        }

        // START: ADDING NEW ELEMENT
        const currentEditorDiv = newLastPage;
        const editorDivHtml = document.createElement('div');
        editorDivHtml.innerHTML = currentEditorDiv.innerHTML;

        if (!editorDivHtml.querySelector(QRCODE_ELEMENT_SELECTOR) && anchorImage) {
          editorDivHtml.appendChild(anchorImage);
        }

        if (!editorDivHtml.querySelector(FOOTER_ELEMENT_SELECTOR) && data) {
          editorDivHtml.appendChild(memoSignature);
        }
        currentEditorDiv.innerHTML = editorDivHtml.innerHTML;

        if (!isFooterLoaded.current) {
          isFooterLoaded.current = true;
          onMount();
        }
      }
    };
    addFooter();
  }, [counter, data, anchorImage]);

  const pageConfig = useMemo(() => {
    return {
      size: {
        width: 816,
        height: 1054,
      },
      position: {
        x: 50,
        y: 150,
      },
      padding: {
        top: 150,
        right: 50,
        bottom: 80,
        left: 50,
      },
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: 14,
      lineHeight: 1,
    };
  }, []);

  const forceUpdate = () => {
    // always increment to force the rendering
    setCounter((prev) => prev + 1);
  };

  function moveToPage(pageIndex: number) {
    if (pageIndex < 0 || pageIndex >= pages.current.length) {
      return;
    }
    currentPageIndex.current = pageIndex;
    editorDivs.current[pageIndex].focus();
    handlePageChange?.(pageIndex);
  }

  // Set the current caret position:
  //nodespath example: [0,0,1,0]: this means cursor is at divEditor.childNodes[0].childNodes[0].childNodes[1].childNodes[0]
  function setCurrentCaretPosition(editorDiv?: HTMLElement) {
    const range = getCurrentCaretRange();
    if (range === null) {
      console.error('Caret not detected on setCurrentCaretPosition');
      return;
    }
    const updatedCaretPosition = {
      caretOffset: range.startOffset,
      nodesPath: [] as number[],
      pageNumber: 0,
    };

    let currentNode = range.startContainer as Element;
    if (!currentNode.parentNode) {
      // it's outside any editor
      caretPosition.current = null;
      return;
    }

    const rootEditor = editorDivs.current
      .filter((s) => !!s)
      .find((div) => {
        return div.contains(currentNode);
      });
    if (!rootEditor) {
      // it's outside any editor
      caretPosition.current = null;
      return;
    }

    const { nodesPath: calculatedNodesPath, currentNode: finalNode } = getNodesPath(
      currentNode,
      rootEditor,
    );

    currentNode = finalNode as Element;
    updatedCaretPosition.nodesPath = calculatedNodesPath;

    if (!editorDiv) {
      // find the page
      const pageIndex = findPageIndex(currentNode, editorDivs.current);

      const found = pageIndex >= 0;

      if (found) {
        currentPageIndex.current = pageIndex;
      } else {
        // the editor div was not found
        console.error('Editor <div> was not found. Resetting to first page');
        moveToPage(0);
        setCaretAtBeginning(editorDivs.current[0]);
        currentPageIndex.current = 0;
        updatedCaretPosition.nodesPath = [0];
        updatedCaretPosition.caretOffset = 0;
      }
    }
    updatedCaretPosition.pageNumber = currentPageIndex.current;
    caretPosition.current = updatedCaretPosition;
  }

  function caretPathExists(editorDiv: Node) {
    const caretNode = navigateToCaretNode(editorDiv, caretPosition.current);
    if (caretNode === null || caretPosition.current === null) {
      return false;
    }

    if (caretNode.nodeType === Node.TEXT_NODE) {
      return caretPosition.current.caretOffset < (caretNode.textContent?.length ?? 0);
    }
    return caretPosition.current.caretOffset < caretNode.childNodes.length;
  }

  function setCaretCursorAtPosition(editorDiv?: Node) {
    editorDiv = editorDiv ?? editorDivs.current[caretPosition.current?.pageNumber ?? 0];
    const caretNode = navigateToCaretNode(editorDiv, caretPosition.current);
    if (caretNode === null || caretPosition.current === null) {
      return;
    }
    const caretRange = document.createRange();

    try {
      // Mantener la lógica actual para establecer el caret
      caretRange.setStart(caretNode, caretPosition.current.caretOffset);
      caretRange.collapse(true);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(caretRange);
        console.debug(
          'setting caret',
          caretRange.collapsed,
          caretRange.startOffset,
          caretRange.startContainer,
        );
      }
    } catch (e) {
      // Buscar el editor y el último nodo de texto si falla el setStart
      const editor = findEditorNode(editorDiv);
      if (editor) {
        let lastTextNode: Node | null = getLastTextNode(editor);
        if (lastTextNode) {
          const lastOffset = lastTextNode.textContent?.length ?? 0;
          const lastRange = document.createRange();
          try {
            lastRange.setStart(lastTextNode, lastOffset);
            lastRange.collapse(true);
            const selection = window.getSelection();
            if (selection) {
              selection.removeAllRanges();
              selection.addRange(lastRange);
              console.debug('Setting caret to the last text node in the editor.');
            }
          } catch (err) {
            console.error('Error setting caret position in the editor:', err);
          }
        }
      }
    }
  }

  function constructPage(
    editorDiv: HTMLDivElement,
    pageIndex: number,
    addPage: (htmlContent?: string) => void,
  ) {
    const pageFocusHandler = () => {
      const previousPage = currentPageIndex.current;
      ignoreSelectionChanges.current = false;
      editorDiv.focus();
      currentPageIndex.current = pageIndex;
      setCurrentCaretPosition(editorDiv);
      if (previousPage !== pageIndex) {
        const range = getCurrentCaretRange();
        handlePageChange?.(pageIndex, range?.endContainer);
      }
    };

    function removePage() {
      editorComponents.current.splice(pageIndex, 1);
      editorDivs.current.splice(pageIndex, 1);
      pages.current.splice(pageIndex, 1);
      ignoreSelectionChanges.current = true;
      forceUpdate();
    }

    const keyDownHandler = (event: KeyboardEvent) => {
      // if we are deleting anything and we are at least in page 2 and we are at the beginning of the page...
      if (
        event.key === 'Backspace' &&
        currentPageIndex.current > 0 &&
        caretIsAtBeginningOfPage(editorDivs.current[pageIndex])
      ) {
        event.preventDefault();
        const caretNode = navigateToCaretNode(editorDiv, caretPosition.current);
        if (caretNode === null) {
          return;
        }

        const previousPageIndex = pageIndex - 1;
        let previousPageEditor = editorDivs.current[previousPageIndex];
        const currentEditor = editorDivs.current[pageIndex];
        removeEmptyLeadingNodes(currentEditor);
        moveToPage(previousPageIndex);
        const firstNode = currentEditor.firstChild;
        const nodeToBeInserted = currentEditor.firstChild?.cloneNode(true);
        if (firstNode === null) {
          return;
        }

        currentEditor.removeChild(firstNode);
        previousPageEditor.appendChild(nodeToBeInserted!);
        pages.current[currentPageIndex.current].overflowChecker();
        previousPageEditor.focus();
        currentPageIndex.current = previousPageIndex;
        setCaretAtEndOfText(previousPageEditor);
        setCurrentCaretPosition(previousPageEditor as HTMLElement);
        setCaretCursorAtPosition(previousPageEditor);
        handlePageChange?.(previousPageIndex);
      } else if (
        event.key === 'Delete' &&
        currentPageIndex.current < pages.current.length - 1 &&
        caretIsAtEndOfPage(editorDiv, caretPosition.current)
      ) {
        event.preventDefault();
        const caretNode = navigateToCaretNode(editorDiv, caretPosition.current);
        if (caretNode === null) {
          return;
        }
        const nextPageIndex = pageIndex + 1;
        let nextPageEditor = editorDivs.current[nextPageIndex];
        removeEmptyLeadingNodes(nextPageEditor);
        const firstContentNode = nextPageEditor.firstChild;
        const nodeToBeInserted = nextPageEditor.firstChild?.cloneNode(true);
        if (firstContentNode === null) {
          return;
        }
        const editorDivOfNextPage = firstContentNode.parentNode!;
        editorDivOfNextPage.removeChild(firstContentNode);

        editorDiv.appendChild(nodeToBeInserted!);
        const currentCaret = caretPosition.current;
        pages.current[currentPageIndex.current].overflowChecker();
        editorDiv.focus();
        caretPosition.current = currentCaret;
        currentPageIndex.current = pageIndex;
        setCaretCursorAtPosition(editorDiv);
        handlePageChange?.(pageIndex);
      } else {
        switch (event.key) {
          case 'ArrowDown':
          case 'ArrowUp':
          case 'ArrowLeft':
          case 'ArrowRight':
            //handleDirection(event.key, event);
            break;
        }
      }
    };

    editorDiv.addEventListener('keydown', keyDownHandler);
    editorDiv.parentNode!.addEventListener('click', pageFocusHandler);

    const nodeEvents = [
      {
        node: editorDiv as Element,
        eventName: 'keydown',
        handler: keyDownHandler as EventListener,
      },
      {
        node: editorDiv.parentNode! as Element,
        eventName: 'click',
        handler: pageFocusHandler as EventListener,
      },
    ];

    let mutationConfig = { subtree: true, childList: true, attributes: true, characterData: true };

    const pageSpec: DocumentPage = {
      caretDirection: null,
      keyDirection: null,
      mutationObserver: null!,
      overflowChecker: null!,
    };

    function checkForPageOverflow() {
      const pageEditor = editorDivs.current[pageIndex];

      if (!pageEditor) {
        return;
      }

      const distanceFromTop = getDistanceFromTopOfLastElement(pageEditor);

      const MAX_ALLOWED_SIZE_FOR_CONTENT =
        pageConfig.size.height - pageConfig.padding.top - pageConfig.padding.bottom;

      const ALLOWED_MARGIN_BETWEEN_FOOTER_AND_CONTENT = 120;

      if (
        distanceFromTop + ALLOWED_MARGIN_BETWEEN_FOOTER_AND_CONTENT <
        MAX_ALLOWED_SIZE_FOR_CONTENT
      ) {
        return;
      }

      pages.current[pageIndex].mutationObserver.disconnect();
      let splittedNodes = splitPage(pageEditor, MAX_ALLOWED_SIZE_FOR_CONTENT, pageConfig);
      pages.current[pageIndex].mutationObserver.observe(pageEditor, mutationConfig);

      if (splittedNodes.length > 0) {
        const isLastPage = currentPageIndex.current === pages.current.length - 1;
        if (isLastPage) {
          // last page
          // when adding a new editor, the div must be created after rendering and all the new content
          // must be handled as "initial content". In order to do that we convert the splitted nodes
          // into HTML string
          const newPageContent = splittedNodes.reduce(
            (htmlContent, node) => htmlContent + (node instanceof Element ? node.outerHTML : ''),
            '',
          );
          addPage(newPageContent);
          handlePageChange?.(currentPageIndex.current + 1);
        } else {
          let nextPage = pages.current[currentPageIndex.current + 1];
          let nextPageContentParentNode = editorDivs.current[currentPageIndex.current + 1];

          nextPage.mutationObserver.disconnect();

          // insert all the splitted nodes
          let previousFirstElement = nextPageContentParentNode.firstChild!;
          let lastElementInserted: Node | null = null;
          splittedNodes.forEach(function (node) {
            nextPageContentParentNode.insertBefore(node, previousFirstElement);
            lastElementInserted = node;
          });

          if (!caretPathExists(pageEditor) && lastElementInserted) {
            // must focus first, otherwise even though the caret might be properly set
            // focus() "restores" the previous caret position.
            editorDivs.current[currentPageIndex.current + 1].focus();
            setCaretAtEndOfText(lastElementInserted);
            currentPageIndex.current = currentPageIndex.current + 1;
            setCurrentCaretPosition(nextPageContentParentNode);
            handlePageChange?.(currentPageIndex.current + 1);
          } else {
            setCaretCursorAtPosition();
          }

          nextPage.mutationObserver.observe(nextPageContentParentNode, mutationConfig);
          nextPage.overflowChecker();

          // set page content
          const updatedPagesContent = pagesContent.map((content, contentIndex) => {
            if (contentIndex === pageIndex) {
              return editors.current[pageIndex].getContent();
            }
            return content;
          });
          setPagesContent(updatedPagesContent);
        }
      } else if (!editorDivs.current[pageIndex + 1]) {
        addPage('');
      }
    }

    function fixOverflowsBetweenPages(changes: MutationRecord[], pageObserver: MutationObserver) {
      if (currentPageIndex.current === pageIndex) {
        setCurrentCaretPosition(editorDiv);
      }
      const pageEditor = editors.current[pageIndex];
      const pageEditorDiv = editorDivs.current[pageIndex];

      if (!pageEditor.isDisposed()) {
        checkForValidFont(changes, pageEditor);
      }

      if (pageIndex > 0 && pageEditorDiv && !isPageContentNonEmpty(pageEditor, pageEditorDiv)) {
        pageObserver.disconnect();
        const previousPageEditorDiv = editorDivs.current[pageIndex - 1];
        currentPageIndex.current = pageIndex - 1;

        setCaretAtEnd(previousPageEditorDiv);
        setCurrentCaretPosition(previousPageEditorDiv);
        removePage();
        return;
      }

      checkForPageOverflow();
    }

    pageSpec.mutationObserver = new MutationObserver(fixOverflowsBetweenPages);
    pageSpec.overflowChecker = checkForPageOverflow;
    pageSpec.mutationObserver.observe(editorDiv, mutationConfig);

    return {
      pageSpec,
      nodeEvents,
    };
  }

  function addEditorPage(initialHtmlContent?: string) {
    const roosterClassNames = 'rooster editor';
    const pageIndex = editorComponents.current.length;

    const componentUuid = uuidv4();

    const updatePlugin = new UpdateStatePlugin({
      handleInputChange: (editorContent: string) => {
        const updatedPagesContent = pagesContent.map((content, contentIndex) => {
          if (contentIndex === pageIndex) {
            return editorContent;
          }
          return content;
        });
        setPagesContent(updatedPagesContent);
      },
    });

    // ribbonPlugin will contain the first ribbon plugin created as the initial value for useState
    const contentEdit = new ContentEdit();
    const imageEdit = new ImageEdit();
    const allPlugins = [
      ...(createPluginsForEditor?.(pageIndex) ?? []),
      updatePlugin as roosterjs.EditorPlugin,
      contentEdit,
      imageEdit,
    ];

    const editorComponent = (
      <Rooster
        contentEditable
        className={roosterClassNames}
        plugins={allPlugins}
        defaultFormat={defaultFormat}
        doNotAdjustEditorColor={true}
        editorCreator={(div, options) => {
          const actualPageIndex = editorComponents.current.findIndex(
            (component) => component.componentKey === componentUuid,
          );
          const editor = new roosterjs.Editor(div, options);
          // preserves content of divs if it already exists
          const pageAlreadyExists = Object.prototype.hasOwnProperty.apply(editorDivs.current, [
            actualPageIndex,
          ]);
          const previousContent = pageAlreadyExists
            ? editorDivs.current[actualPageIndex].innerHTML
            : '';
          editors.current[actualPageIndex] = editor;
          editorDivs.current[actualPageIndex] = div;

          if (pageAlreadyExists) {
            pages.current[actualPageIndex].mutationObserver.disconnect();
            div.innerHTML = previousContent;
          } else if (initialHtmlContent) {
            currentPageIndex.current = actualPageIndex;
            div.innerHTML = initialHtmlContent;
            // this means that we have added a new page so we have to put the focus at the end of the content
            if (div.childNodes[0]) {
              setCaretAtEnd(div.childNodes[0]);
            } else {
              setCaretAtEnd(div);
            }
            setCurrentCaretPosition(div);
          }

          if (
            !initialHtmlContent &&
            caretPosition.current &&
            caretPosition.current.pageNumber === actualPageIndex
          ) {
            try {
              setCaretCursorAtPosition(div);
            } catch (error) {
              // this error only happens if text or objects have been removed due to adding a new page
              // in that case only ignore, for other cases we throw the error
              if (
                !(
                  error instanceof DOMException &&
                  (error.name === 'IndexSizeError' || error.code === 1)
                )
              ) {
                throw error;
              }
            }
          } else if (actualPageIndex === 0 && !caretPosition.current) {
            // initial load
            caretPosition.current = {
              caretOffset: 0,
              nodesPath: [],
              pageNumber: 0,
            };
          }

          // used to avoid React's double mounting bug
          initialHtmlContent = undefined;

          applyEditorDivStyle(div, pageConfig, backgroundUrl);
          const pageSpecWithEvents = constructPage(div, actualPageIndex, addEditorPage);
          pages.current[actualPageIndex] = pageSpecWithEvents.pageSpec;
          return editor;
        }}
      />
    );

    editorComponents.current = [
      ...editorComponents.current,
      { component: editorComponent, componentKey: componentUuid },
    ];
    forceUpdate();
  }

  let analyzingResize = false;

  function resizePreview() {
    if (analyzingResize) {
      return;
    }
    analyzingResize = true;
    for (const contentDiv of editorDivs.current.filter((s) => !!s)) {
      applyEditorDivStyle(contentDiv, pageConfig, backgroundUrl);
    }
    analyzingResize = false;
  }

  useEffect(() => {
    const currentEditors = editors.current;
    const currentEditorDivs = editorDivs.current;
    const currentPages = pages.current;

    addEditorPage();

    function onSelectionChange() {
      if (ignoreSelectionChanges.current) {
        return;
      }

      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) {
        return;
      }

      const range = selection.getRangeAt(0);
      if (pageContainer.current && isCursorMovement(range, pageContainer.current)) {
        setCurrentCaretPosition();
        handlePageChange?.(currentPageIndex.current, range.endContainer);
      }
    }

    document.addEventListener('selectionchange', onSelectionChange);

    return () => {
      console.debug('Removing editor');
      editorComponents.current.splice(0);
      currentEditors.splice(0).forEach((editor) => {
        if (!editor.isDisposed) {
          editor.dispose();
        }
      });
      currentEditorDivs.splice(0);
      currentPages.splice(0).forEach((spec) => {
        spec.mutationObserver.disconnect();
      });
      forceUpdate();

      document.removeEventListener('selectionchange', onSelectionChange);
    };
  }, []);

  useEffect(() => {
    const pagesContainer = pageContainer.current;
    if (!pagesContainer) {
      console.error('<div#pages-container> is not defined');
      return;
    }

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        resizePreview();
      }, 100),
    );
    resizeObserver.observe(pagesContainer);

    // set the current care at correct place
    if (caretPosition.current) {
      // console.log(caretPosition.current)
      editorDivs.current[caretPosition.current.pageNumber].focus();
      setCaretCursorAtPosition();
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [counter]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getContent: () => {
          const newContentEditors: string[] = editors.current
            .map((editor) => {
              if (!editor.isDisposed()) {
                return editor.getContent();
              }

              return '';
            })
            .filter((content) => content.length > 0);
          return newContentEditors;
        },
        setContent: async (newContent: string) => {
          const maxCharactersPerPage = 3100;
          const contentSegments = [];

          for (let i = 0; i < newContent.length; i += maxCharactersPerPage) {
            const segment = newContent.substring(i, i + maxCharactersPerPage);
            contentSegments.push(segment);
          }

          contentSegments.forEach((segment, index) => {
            if (index >= editorDivs.current.length) {
              addEditorPage(segment);
            } else {
              const editorDiv = editorDivs.current[index];
              const footer = editorDiv.querySelector(FOOTER_ELEMENT_SELECTOR)?.outerHTML || '';
              const qrCode = editorDiv.querySelector(QRCODE_ELEMENT_SELECTOR)?.outerHTML || '';
              editorDiv.innerHTML = segment;

              if (footer && qrCode && index === contentSegments.length - 1) {
                editorDiv.insertAdjacentHTML('beforeend', footer);
                editorDiv.insertAdjacentHTML('beforeend', qrCode);
              }
            }
          });
        },
        getConfiguration() {
          return pageConfig;
        },
        setHTMLTemplateContent(HTMLTemplateContent: string) {
          editors.current[0].focus();
          editors.current[0].setContent(HTMLTemplateContent);

          editorDivs.current.splice(1);
          pages.current.splice(1).forEach((page) => {
            page.mutationObserver.disconnect();
          });
          setPagesContent([HTMLTemplateContent]);
          editorComponents.current = [editorComponents.current![0]];
          currentPageIndex.current = 0;
          caretPosition.current = {
            caretOffset: 0,
            nodesPath: [0],
            pageNumber: 0,
          };
          pages.current[0].overflowChecker();
          forceUpdate();
        },
      };
    },
    [pageConfig],
  );

  return (
    <>
      <div key={counter} id="pages-container" ref={pageContainer}>
        {editorComponents.current.map((e) => (
          <div className="page" key={e.componentKey}>
            {e.component}
          </div>
        ))}
      </div>
      <SoftLoading isOpen={isGettinConclusion} />
    </>
  );
});

export default TextEditor;
