import addInitialTemplate from './addInitialTemplate';
import { registerButton } from './buttonRegistryFactory';
import createAddFooterButton from './createAddFooterRibbonButton';
import createTemplatesButton from './createTemplatesRibbonButton';
import lineHeightSelector from './lineHeightSelector';
import recordButton from './recordButton';
import translateReport from './translateReport';
import uploadAudioButton from './uploadAudioButton';

registerButton('addFooter', createAddFooterButton);
registerButton('templates', createTemplatesButton);
registerButton('uploadAudio', uploadAudioButton);
registerButton('record', recordButton);
registerButton('translate', translateReport);
registerButton('translate', addInitialTemplate);
registerButton('lineHeight', lineHeightSelector);
