import React from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import FormDialog from '../../add/components/FormDialog';
import { InitialValues } from '../../add/components/FormDialog/FormDialogProps';
import Header from './Header';
import useSx from './sx';
import useEditTemplateState from './useEditTemplateState';

const EditTemplate = () => {
  const navigate = useNavigate();
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const {
    deviceWidth,
    updateTemplate,
    addTemplate,
    removeTemplate,
    template,
    canUserEditTemplate,
    setIsDeleteDialogOpen,
    isDeleteDialogOpen,
    isTemplateFetched,
  } = useEditTemplateState();

  const onSave = async (values: InitialValues) => {
    const updateTemplateResponse = await updateTemplate({
      templateId: template?.templateId,
      ...values,
    });
    if (!updateTemplateResponse.success) {
      showSnackbar({
        message: 'La plantilla no ha sido actualizada.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }

    showSnackbar({
      message: 'Plantilla se actualizó con éxito',
      title: 'Plantilla actualizada',
      type: 'success',
    });
  };

  const onAddNew = async (values: InitialValues) => {
    const addTemplateResponse = await addTemplate({
      ...values,
      templateName: values.templateName + ' (copy)',
      organizationWide: false,
    });
    if (!addTemplateResponse.success) {
      showSnackbar({
        message: 'La plantilla no ha sido guardada.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }

    navigate(`/templates/${addTemplateResponse.template.templateId}`);
    showSnackbar({
      message: 'Plantilla configurada con éxito',
      title: 'Plantilla guardada',
      type: 'success',
    });
  };

  const onDelete = async () => {
    setIsDeleteDialogOpen(true);
  };

  const onDeleteConfirm = async () => {
    const response = await removeTemplate(template?.templateId?.toString() || '');
    if (response.success) {
      showSnackbar({
        message: 'Plantilla eliminada con éxito',
        title: 'Plantilla eliminada',
        type: 'success',
      });
      navigate('/templates');
      return;
    }

    showSnackbar({
      message: 'La plantilla no ha sido eliminada',
      title: 'Ocurrió un error',
      type: 'error',
    });
  };

  return (
    <Container sx={sx.root} maxWidth={deviceWidth} disableGutters>
      <Header />
      <If condition={isTemplateFetched}>
        <ContainerCard>
          <If condition={!canUserEditTemplate}>
            <Alert severity="warning">
              No cuentas con los permisos para editar esta plantilla.
            </Alert>
          </If>
          <FormDialog
            title="Editar plantilla"
            canUserEditTemplate={canUserEditTemplate || false}
            onSave={canUserEditTemplate ? onSave : undefined}
            onAddNew={onAddNew}
            onDelete={canUserEditTemplate ? onDelete : undefined}
            initialValues={{
              templateName: template?.templateName || '',
              content: template?.content || '',
              folderName: template?.folderName || '',
              organizationWide: template?.organizationWide || false,
            }}
          />
        </ContainerCard>
      </If>
      <ActionDialog
        type="delete"
        title="Confirmar eliminación"
        message="¿Estás seguro de que deseas eliminar esta plantilla? Esta acción no se puede deshacer."
        open={isDeleteDialogOpen}
        onConfirm={onDeleteConfirm}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
        }}
      />
    </Container>
  );
};

export default EditTemplate;
