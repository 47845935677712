import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@components/Button/IconButton';
import useSx from './sx';

export interface ShareLinkProps {
  label?: string;
  onClick?: VoidFunction;
}

const ShareLink = ({ children, label, onClick }: PropsWithChildren<ShareLinkProps>) => {
  const sx = useSx(label);

  return (
    <Stack sx={sx.root}>
      <IconButton sx={sx.iconButton} tooltip="" type="button" onClick={onClick}>
        {children}
      </IconButton>
      <Typography variant="body2">{label}</Typography>
    </Stack>
  );
};

export default ShareLink;
