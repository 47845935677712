import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Template } from '@core/types';
import TableProps from './TableProps';
import TemplateCell from './TemplateCell';
import useSx from './sx';

const Table = ({ templates }: TableProps) => {
  const navigate = useNavigate()
  const sx = useSx()

  const handleTemplateClick = (template: Template) => {
    navigate(`/templates/${template.templateId}`)
  }
  
  return (
    <TableContainer sx={sx.root} component={Paper}>
      <MuiTable aria-label="simple table">
        <MuiTableHead>
          <TableCell>Nombre</TableCell>
          <TableCell>Organización</TableCell>
          <TableCell align='center'>&Uacute;ltima modificación</TableCell>
        </MuiTableHead>
        <TableBody>
          {templates.map((template) => (
            <TableRow
              key={template.templateId}
              className='table-row'
              onClick={() => {handleTemplateClick(template)}}
            >
              <TemplateCell template={template} />
              <TableCell>
                {template.organizationWide ? 'Organización' : ''}
              </TableCell>
              <TableCell align='center'>{dayjs(template.modifiedDate).format('DD/MM/YYYY h:mm A')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table