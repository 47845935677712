import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        paddingX: {xs: '16px', md: '0'},
        gap: {xs: "10px", md: "20px"},
        '.subtitle': {
            fontSize: {xs: '0.8rem', md: '1.3125rem'}
        }
    }
});

export default useSx;
