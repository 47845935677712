import React from 'react'
import MuiPagination, { PaginationProps } from '@mui/material/Pagination';
import useSx from './sx'

const Pagination = (props: PaginationProps) => {
    const sx = useSx()

    return (
        <MuiPagination sx={sx.root} {...props} />
    )
}

export default Pagination