import React, { useState } from 'react'
import useSnackbar from '@core/hooks/useSnackbar';
import { GetStudyResponse } from '@core/types';
import useAssignTechnician from 'app/features/studies/hooks/useAssignTechnician';
import useGetStudyAssignedTechnician from 'app/features/studies/hooks/useGetStudyAssignedTechnician';
import useUsers from 'app/features/users/hooks/useUsers';

const useFormState = ({ isOpen, studyInstanceUid }: {isOpen: boolean, studyInstanceUid: string, studyInfo: Partial<GetStudyResponse> | undefined}) => {
    const showSnackbar = useSnackbar()
    const { assignTechnicianAsync } = useAssignTechnician()
    const { technician } = useGetStudyAssignedTechnician(studyInstanceUid, isOpen)

    const [filters, setFilters] = useState({
      Role: 'Technician',
      Search: '',
      Order: 'ASC',
    })
    const [selectedTechnicianInput, setSelectedTechnicianInput] = useState<any>(null)
    const { users, isLoading } = useUsers({ filters })
    const selectedTechnician = technician?.user
  
    return {
      showSnackbar,
      selectedTechnicianInput,
      setSelectedTechnicianInput,
      setFilters,
      selectedTechnician,
      assignTechnicianAsync,
      users,
      isLoading,
      filters,
    }
}

export default useFormState