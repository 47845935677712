import React, { useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { Editor } from 'roosterjs-editor-core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useGetTemplateTags from 'app/features/templates/hooks/useGetTemplateTags';
import useSx from '../sx';

const TemplateTagDropdown = ({ editor }: { editor: React.RefObject<Editor> }) => {
  const sx = useSx();
  const [filters, setFilters] = useState({
    Page: '1',
    Search: '',
  });
  const { templateTags, isLoading } = useGetTemplateTags(filters);

  const handleTagChange = (value: string): void => {
    if (!!value) {
      setFilters((prev) => ({
        ...prev,
        Search: value,
      }));
    }
  };

  const handleChangeDebounced = useMemo(
    () => debounce(handleTagChange, 300),
    // we are creating a closure when using debounce thus we
    // need to make filters a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  const handleTemplateTagChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: { templateTagId: number; name: string } | null,
  ) => {
    const htmlString = editor.current?.getContent();

    const tempDiv = document.createElement('div');
    if (tempDiv) tempDiv.innerHTML = htmlString || '';

    const lastDiv = tempDiv.lastElementChild;

    const newSpan = document.createElement('span')!;
    newSpan.textContent = value?.name ? `{{${value.name}}}` : '';
    newSpan.style.backgroundColor = 'lightgray';

    if (value?.name) {
      newSpan.className = value.name;
    }

    if (lastDiv) lastDiv.appendChild(newSpan);

    newSpan.outerHTML = '&#160;' + newSpan.outerHTML + '&#160;';

    const newHTML = tempDiv.innerHTML;

    editor.current?.setContent(newHTML);
  };

  return (
    <Autocomplete
      options={templateTags?.items || []}
      renderOption={(props, option) => (
        <li {...props} key={option.name}>
          {option.name}
        </li>
      )}
      getOptionLabel={(option) => option.name}
      clearOnBlur
      blurOnSelect
      clearOnEscape
      value={null}
      loading={isLoading}
      onChange={handleTemplateTagChange}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={($e) => {
            handleChangeDebounced($e.target.value);
          }}
          fullWidth
          label="Seleccionar Etiqueta"
          className="input"
          sx={sx.templateDropdown}
        />
      )}
    />
  );
};

export default TemplateTagDropdown;
