import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addTemplate } from '../../add/api/addTemplate';
import { QUERY_KEY as GET_TEMPLATE_QUERY_KEY } from '../../hooks/useGetTemplates';
import { Payload } from '../types/templatePayload';

const useAddTemplate = () => {
  const queryClient = useQueryClient()

  const { isLoading: isUploading, mutateAsync: asyncAddTemplate } = useMutation({
    mutationFn: (payload: Payload) => addTemplate(payload),
    mutationKey: ["TemplateAdd"],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TEMPLATE_QUERY_KEY])
    }
 });

  return { isUploading, addTemplate: asyncAddTemplate };
};

export default useAddTemplate;
