import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        paddingLeft: '25px',
        '.pdf-content-container': {
            display: 'flex', 
            alignItems: 'center', 
            gap: '5px'
        },
        '.template-icon': {
            color: (theme) => theme.palette.primary.main        
        }
    }
});

export default useSx;
