import commandsModule from './commandsModule';
import toolbarModule from './toolbarModule';

const evodicomExtension = (generatedReportIds) => ({
  id: 'evodicom',
  getToolbarModule() {
    return toolbarModule(generatedReportIds);
  },
  getCommandsModule() {
    return commandsModule;
  },
});

export default evodicomExtension;
