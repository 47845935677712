import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import useGetTemplates from 'app/features/templates/hooks/useGetTemplates';
import useSx from './sx';

const zIndexForComponents = { sx: { zIndex: 100000 } };

const componentsProps = {
  popupIndicator: zIndexForComponents,
  paper: zIndexForComponents,
  popper: zIndexForComponents,
  clearIndicator: zIndexForComponents,
};

const TemplateCategoriesDropdown = ({ onChange }: { onChange: (folderName: string) => void }) => {
  const sx = useSx();

  const [folderName, setFolderName] = useState('');
  const [filters, setFilters] = useState({
    Page: '1',
    Search: '',
  });
  const { templates, isLoading } = useGetTemplates(filters);

  const handleCategoryChange = (value: string): void => {
    if (!!value) {
      setFilters((prev) => ({
        ...prev,
        Search: value,
      }));
    }
  };

  const handleCategoryChangeDebounce = useCallback(debounce(handleCategoryChange, 1000), []);

  const values = useMemo(
    () =>
      [...new Set(templates?.items.map((template) => template.folderName))].filter(
        (s) => s !== '',
      ) || [],
    [templates?.items],
  );

  return (
    <>
      <Autocomplete
        fullWidth
        freeSolo
        inputValue={folderName}
        onInputChange={(_, newInputValue) => {
          setFolderName(newInputValue);
          handleCategoryChangeDebounce(newInputValue);
          onChange(newInputValue);
        }}
        componentsProps={componentsProps}
        options={values}
        renderOption={(props, value) => (
          <Typography {...props} component="li" key={value} sx={sx.list}>
            {value}
          </Typography>
        )}
        loading={isLoading}
        onChange={(_, value) => {
          if (!value) {
            setFolderName(value || '');
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Plantillas"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={sx.templateDropdown}
          />
        )}
      />
    </>
  );
};

export default TemplateCategoriesDropdown;
