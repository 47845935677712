import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ d: 'column' }),
    flex: 1,
    gap: 'inherit',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  input: (theme) => ({
    marginBottom: '1rem',
    ...(theme.palette.mode === 'dark' ? { border: 'none' } : {}),
    '& .MuiInputBase-input': {
      paddingLeft: '9px',
    },
    '.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
  }),
  templateDropdown: {
    marginBottom: '1rem',
    '& .MuiInputLabel-root': {
      top: '-7px',
    },
    '& .MuiOutlinedInput-root': {
      paddingY: 0,
    },
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    marginBottom: '1rem',
    '.MuiTabs-indicator':{
      backgroundColor: 'transparent'
    },
    '& .Mui-selected': {
      color: (theme) => `${theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[300]} !important`,
    },
    '.tab': {
      color: (theme) =>
        theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[300],
      ':hover': { cursor: 'default' },
    },
  },
});

export default useSx;
