import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as GET_TEMPLATE_QUERY_KEY } from '../../hooks/useGetTemplates';
import { Payload } from '../../types/templatePayload';
import { updateTemplate } from '../api/updateTemplate';
import { QUERY_KEY as GET_SINGLE_TEMPLATE_QUERY_KEY } from '../hooks/useGetSingleTemplate';

const useUpdateTemplate = () => {
  const queryClient = useQueryClient()

  const { isLoading: isUploading, mutateAsync: asyncUpdateTemplate } = useMutation({
    mutationFn: (payload: Payload) => updateTemplate(payload),
    mutationKey: ["TemplateUpdate"],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TEMPLATE_QUERY_KEY])
      queryClient.invalidateQueries([GET_SINGLE_TEMPLATE_QUERY_KEY])
    }
 });

  return { isUploading, updateTemplate: asyncUpdateTemplate };
};

export default useUpdateTemplate;
