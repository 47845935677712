import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        "& .MuiPagination-ul li:last-child": {
            marginLeft: "16px",
        },
        "& .MuiPagination-ul li:last-child button::before": {
            content: "'Next'",
            marginRight: "8px",
        },
        "& .MuiPagination-ul li:first-child": {
            marginRight: "16px",
        },
        "& .MuiPagination-ul li:first-child button::after": {
            content: "'Previous'",
            marginLeft: "8px",
        },
        margin: '1.5rem auto', 
        display: 'flex',
        '.MuiPagination-ul': {
            marginX: 'auto'
        }
    }
});

export default useSx;
