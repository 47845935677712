import React, { createRef, useState, useRef, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { EditorPlugin } from 'roosterjs-editor-types';
import { Ribbon, RibbonPlugin, createRibbonPlugin } from 'roosterjs-react';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ActionButton from '@components/Button/ActionButton';
import FullScreenLoader from '@components/FullScreenLoader';
import If from '@components/If';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useSnackbar from '@core/hooks/useSnackbar';
import getTemplateTags from '@core/utils/getTemplateTags';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import useAddTemplate from 'app/features/templates/add/hooks/useAddTemplate';
import useGetSingleTemplate from 'app/features/templates/edit/hooks/useGetSingleTemplate';
import { initialTemplate } from '../../constants/initialTemplate';
import { FOOTER_ELEMENT_SELECTOR, QRCODE_ELEMENT_SELECTOR } from '../../constants/reportElements';
import useGetDicomTemplateTagsInfo from '../../hooks/useGetDicomTemplateTagsInfo';
import useGetFilteredButtons from '../../hooks/useGetFilteredButtons';
import replaceTemplateTags from '../../utils/replaceTemplateTags';
import AddTemplateDialog from './AddTemplateDialog';
import FormDialogProps from './FormProps';
import RecordAudioAction from './RecordAudioAction';
import TemplateDropdown from './TemplateDropdown';
import TemplateTagsDialog from './TemplateTagsDialog';
import TextEditor from './TextEditor';
import TranslatorButton from './TranslatorButton';
import UploadAudioAction from './UploadAudioAction';
import { TextEditorMethods } from './documentTypes';
import validationSchema from './formValidation';
import useFormState from './useFormState';

const ADD_TEMPLATE_ERROR_MESSAGE =
  'La plantilla no se pudo agregar. Por favor, asegúrate de que el título sea único y verifica que el reporte tenga contenido para poder guardarlo como plantilla';

interface FormValues {
  name: string;
}

const Form = ({
  handleReportSubmit,
  initForm,
  isLoading,
  studyId,
  handleCancel,
}: FormDialogProps) => {
  const {
    sx,
    isLoadingShareUrl,
    templateId,
    templateImageUrl,
    isTemplateImageLoading,
    study,
    isTranscribing,
    setIsTranscribing,
    organizationTemplates,
  } = useFormState(studyId);
  const initialValues = {
    selectedTemplate: '',
    name: initForm?.name || '',
  };
  const textEditorRef = createRef<TextEditorMethods>();
  const [ribbonPlugin, setRibbonPlugin] = useState(createRibbonPlugin());
  const ribbonPlugins = useRef<Array<RibbonPlugin>>([ribbonPlugin]);
  const showSnackbar = useSnackbar();

  const [isMounted, setIsMounted] = useState(false);

  const getDicomValue = useGetDicomTemplateTagsInfo({ study });

  const [variables, setVariables] = useState(getTemplateTags(initialTemplate));

  const formikRef = useRef<
    FormikProps<{
      selectedTemplate: string;
      name: string;
    }>
  >(null);

  const [dialog, setDialog] = useState<'TEMPLATES' | 'ADD_TEMPLATE' | null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const { template, isLoading: isSingleTemplateLoading } = useGetSingleTemplate(
    selectedTemplateId || '',
  );

  // ribbon related state
  const theme = useTheme();
  const { filteredButtons } = useGetFilteredButtons(
    theme.palette.mode,
    study!,
    organizationTemplates?.items || [],
  );

  const createPluginsForPage = (pageNumber: number): Array<EditorPlugin> => {
    if (pageNumber >= ribbonPlugins.current.length) {
      ribbonPlugins.current[pageNumber] = createRibbonPlugin();
    }

    return [ribbonPlugins.current[pageNumber]];
  };

  const headerHeight = 170;
  const editorPageChange = (pageNumber: number, focusedNode?: Node) => {
    if (pageNumber >= ribbonPlugins.current.length) {
      return;
    }
    setRibbonPlugin(ribbonPlugins.current[pageNumber]);
    if (focusedNode && textEditorRef.current != null) {
      const documentConfiguration = textEditorRef.current.getConfiguration();
      const element = (
        focusedNode.nodeType == Node.TEXT_NODE ? focusedNode.parentNode : focusedNode
      ) as Element;
      const elementRect = element.getBoundingClientRect();

      // Scroll to the element, considering the fixed header
      window.scrollTo({
        top: elementRect.top - headerHeight - documentConfiguration.position.y,
      });
    }
  };

  const handleSubmitFile = async (values: FormValues) => {
    if (!textEditorRef.current) {
      return;
    }
    const reportContent = textEditorRef.current.getContent();
    if (reportContent === null || reportContent.length === 0) {
      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible crear el reporte.',
      });
      return;
    }
    const documentConfiguration = textEditorRef.current.getConfiguration();

    const document = {
      pages: reportContent,
      configuration: documentConfiguration,
      organizationTemplateId: templateId.length > 0 ? templateId : null,
      title: values.name,
    };

    await handleReportSubmit(document);
  };

  const backgroundLayerStyle = {
    position: 'fixed',
    top: 0,
    width: 'inherit',
    height: '210px',
    pointerEvents: 'none',
    zIndex: 500,
    backgroundColor: theme.palette.background.default,
    maxWidth: 'inherit',
  } as React.CSSProperties;

  const handleUpdateTextEditorContent = async (newContent: string) => {
    if (textEditorRef.current && newContent) {
      try {
        textEditorRef.current.setContent(newContent);
      } catch (error) {
        console.error('Error updating editor content:', error);
      }
    }
  };

  const onTemplateSelectConfirm = (newTemplateId: string) => {
    setSelectedTemplateId(newTemplateId);
    setDialog('TEMPLATES');
  };

  const { addTemplate, isUploading } = useAddTemplate();

  const onTemplateAdd = async (values: {
    folderName: string;
    title: string;
    organizationWide: boolean;
  }) => {
    const reportContent = textEditorRef.current?.getContent();

    if (reportContent) {
      try {
        const doc = document.createElement('div');
        doc.innerHTML = reportContent.join(' ');
        const signature = doc.querySelector(FOOTER_ELEMENT_SELECTOR);
        const qrcode = doc.querySelector(QRCODE_ELEMENT_SELECTOR);

        if (signature) signature.remove();
        if (qrcode) qrcode.remove();

        doc.childNodes.forEach((node) => {
          const element = node as HTMLElement;
          if (element.style) {
            (element as HTMLElement).style.color = '';
          }
        });

        if (doc.innerText.length === 0) throw new Error('El reporte debe tener contenido');

        const response = await addTemplate({
          folderName: values.folderName,
          templateName: values.title,
          content: doc.innerHTML,
          organizationWide: values.organizationWide,
        });

        if (isCustomErroHandlerResponse(response))
          throw new Error('Error al intentar agregar la plantilal');

        setDialog(null);
        showSnackbar({
          type: 'success',
          title: 'Plantilla agregada',
          message: 'La plantilla se ha añadido con éxito.',
        });
      } catch (err: any) {
        console.log(err.message);
        showSnackbar({
          type: 'error',
          title: 'Error al agregar la plantilla',
          message: ADD_TEMPLATE_ERROR_MESSAGE,
        });
      }
    }
  };

  const onMountTextEditor = () => {
    const newHtml = replaceTemplateTags(initialTemplate, variables, getDicomValue);
    textEditorRef.current?.setHTMLTemplateContent(newHtml);

    setIsMounted(true);
  };

  useEffect(() => {
    Object.keys(variables).forEach((key) => {
      if (getDicomValue(key)) {
        variables[key] = getDicomValue(key);
      }
    });
    setVariables(variables);
  }, [study]);

  const hiddenDivToAddInitialTemplate = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickOnHiddenDiv = () => {
      hiddenDivToAddInitialTemplate.current?.click();
    };

    if (isMounted) {
      subscribe('evodicom.report.initialTemplate', clickOnHiddenDiv);
    }
    return () => {
      unsubscribe('evodicom.report.initialTemplate', clickOnHiddenDiv);
    };
  }, [isMounted]);

  const addInitialTemplate = () => {
    const html = textEditorRef.current?.getContent();
    if (html !== undefined) {
      const htmlWithReplacedTags = replaceTemplateTags(initialTemplate, variables, getDicomValue);

      textEditorRef.current?.setHTMLTemplateContent(htmlWithReplacedTags + html);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitFile}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ values, handleSubmit, handleChange, errors, resetForm, isValid }) => {
          return (
            <>
              <div style={backgroundLayerStyle} />
              <If condition={isValid}>Valid</If>
              <Box
                flexDirection="column"
                display="flex"
                style={{ position: 'fixed', maxWidth: 'inherit', width: 'inherit', zIndex: 1000 }}
              >
                <Box sx={sx.inputs} height="57px">
                  <Box flex={1} className="input-container">
                    <TextField
                      placeholder="Titulo"
                      name="name"
                      sx={{ width: '100%' }}
                      value={values.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      inputProps={{ sx: sx.inputName }}
                    />
                  </Box>
                  <Box flex={1} className="input-container">
                    <TemplateDropdown onCancel={() => {}} onConfirm={onTemplateSelectConfirm} />
                  </Box>
                  <Box sx={sx.topButtons}>
                    <ActionButton
                      onClick={() => {
                        handleCancel();
                        resetForm();
                      }}
                      text="Cancelar"
                      variant="outlined"
                      color="secondary"
                    />

                    <ActionButton
                      text="Plantillas"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setDialog('ADD_TEMPLATE');
                      }}
                      disabled={!isValid}
                      isLoading={isLoading}
                    />
                    <ActionButton
                      text="Guardar"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={!isValid}
                      isLoading={isLoading}
                    />
                  </Box>
                </Box>
                <Ribbon
                  buttons={filteredButtons}
                  plugin={ribbonPlugin}
                  overflowButtonProps={{
                    menuProps: {
                      className:
                        theme.palette.mode === 'light'
                          ? 'dropdown-menu--light'
                          : 'dropdown-menu--dark',
                      items: [],
                    },
                  }}
                />
              </Box>
              <Box
                sx={sx.root}
                position={'relative'}
                maxWidth="inherit"
                width="100%"
                component="form"
              >
                <If condition={!isLoadingShareUrl && !isTemplateImageLoading}>
                  <TextEditor
                    backgroundUrl={templateImageUrl}
                    ref={textEditorRef}
                    study={study}
                    createPluginsForEditor={createPluginsForPage}
                    handlePageChange={editorPageChange}
                    onMount={onMountTextEditor}
                  />
                </If>
              </Box>
            </>
          );
        }}
      </Formik>
      <TranslatorButton textEditorRef={textEditorRef} />
      <If condition={!!template}>
        <TemplateTagsDialog
          onHTMLReady={(newHtml) => {
            textEditorRef.current?.setHTMLTemplateContent(newHtml || '');
          }}
          isOpen={dialog === 'TEMPLATES'}
          setIsOpen={setDialog}
          template={template}
          study={study}
          isSingleTemplateLoading={isSingleTemplateLoading}
        />
      </If>
      <If condition={isTranscribing}>
        <FullScreenLoader transparent />
      </If>
      <RecordAudioAction
        onUpdateTextEditor={handleUpdateTextEditorContent}
        isTranscribing={isTranscribing}
        setIsTranscribing={setIsTranscribing}
      />
      <UploadAudioAction
        onUpdateTextEditor={handleUpdateTextEditorContent}
        isTranscribing={isTranscribing}
        setIsTranscribing={setIsTranscribing}
      />
      <AddTemplateDialog
        isLoading={isUploading}
        onAccept={onTemplateAdd}
        onCancel={() => {
          setDialog(null);
        }}
        open={dialog === 'ADD_TEMPLATE'}
      />
      <div
        ref={hiddenDivToAddInitialTemplate}
        style={{ display: 'none' }}
        onClick={addInitialTemplate}
      ></div>
    </>
  );
};

export default Form;
