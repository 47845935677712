const clearReportsAssociatedQueries = () => {
  const event = new CustomEvent('evodicom.report.added');
  document.dispatchEvent(event);
};

const actions = {
  fireEvent: ({ commandName }) => {
    if (commandName === 'report') {
      // Retrieve the base URL dynamically
      const baseUrl = window.location.origin;

      // Split the current URL path and extract the studyUid
      const pathSegments = window.location.pathname.split('/');
      const studyIndex = pathSegments.findIndex((segment) => segment === 'studies');
      const studyUid = pathSegments[studyIndex + 1];

      // Check if studyUid is available
      if (!studyUid) {
        console.error('No studyUid found in URL path');
        return;
      }

      // Construct the URL using the base URL and study UID
      const url = `${baseUrl}/reports?studyUid=${studyUid}`;

      // Open the new URL in a new tab
      const reportWindow = window.open(url, '_blank');
      window.onSuccessResponse = () => {
        reportWindow.close();
        clearReportsAssociatedQueries();
        window.location.reload();
      };
      window.onCancelReport = () => {
        reportWindow.close();
      };
    } else if (commandName === 'side-report') {
      const event = new CustomEvent('evodicom.handleSideReport', { detail: { commandName } });
      document.dispatchEvent(event);
    } else {
      // Original behavior for other commands
      const event = new CustomEvent('evodicom.command.click', { detail: { commandName } });
      document.dispatchEvent(event);
    }
  },
  downloadReport: ({ viewports }) => {
    if (!viewports) {
      return;
    }

    if (viewports.activeViewportIndex < 0) {
      return;
    }
    const activeViewport = viewports.viewportSpecificData[viewports.activeViewportIndex];
    const studyId = activeViewport.StudyInstanceUID;
    const seriesId = activeViewport.SeriesInstanceUID;
    const objectId = activeViewport.SOPInstanceUID;
    const event = new CustomEvent('evodicom.report.download', {
      detail: {
        studyId,
        seriesId,
        objectId,
      },
    });
    document.dispatchEvent(event);
  },
  printReport: ({ viewports }) => {
    if (!viewports) {
      return;
    }

    if (viewports.activeViewportIndex < 0) {
      return;
    }
    const activeViewport = viewports.viewportSpecificData[viewports.activeViewportIndex];
    const studyId = activeViewport.StudyInstanceUID;
    const seriesId = activeViewport.SeriesInstanceUID;
    const objectId = activeViewport.SOPInstanceUID;
    const event = new CustomEvent('evodicom.report.print', {
      detail: {
        studyId,
        seriesId,
        objectId,
      },
    });
    document.dispatchEvent(event);
  },
  printReportWithoutTemplate: ({ viewports }) => {
    if (!viewports) {
      return;
    }

    if (viewports.activeViewportIndex < 0) {
      return;
    }
    const activeViewport = viewports.viewportSpecificData[viewports.activeViewportIndex];
    const studyId = activeViewport.StudyInstanceUID;
    const seriesId = activeViewport.SeriesInstanceUID;
    const objectId = activeViewport.SOPInstanceUID;
    const event = new CustomEvent('evodicom.report.printNoTemplate', {
      detail: {
        studyId,
        seriesId,
        objectId,
      },
    });
    document.dispatchEvent(event);
  },
  printPhoto: (props) => {
    const activeViewportIndex = props.viewports.activeViewportIndex;
    const event = new CustomEvent('evodicom.printPhoto', {
      detail: {
        activeViewportIndex,
      },
    });
    document.dispatchEvent(event);
  },
};

const commandsModule = {
  defaultContext: 'VIEWER',
  actions,
  definitions: {
    fireEvent: {
      commandFn: actions.fireEvent,
      storeContexts: [],
      options: {},
    },
    printPhoto: {
      commandFn: actions.printPhoto,
      storeContexts: ['viewports'],
      options: {},
    },
    downloadReport: {
      commandFn: actions.downloadReport,
      storeContexts: ['viewports'],
      options: {},
    },
    printReport: {
      commandFn: actions.printReport,
      storeContexts: ['viewports'],
      options: {},
    },
    printReportWithoutTemplate: {
      commandFn: actions.printReportWithoutTemplate,
      storeContexts: ['viewports'],
      options: {},
    },
  },
};

export default commandsModule;
