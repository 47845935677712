import * as React from 'react';
import { Box, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TemplateIcon from '@assets/icons/Template';
import TemplateCellProps from './TemplateCellProps';
import useSx from './sx';

const TemplateCell = ({ template }: TemplateCellProps) => {

  const sx = useSx()

  return (
    <TableCell sx={sx.root} component="th" scope="row">
        <Box className="pdf-content-container">
            <TemplateIcon className="template-icon" />
            <Typography>
                {template.templateName}
            </Typography>
        </Box>
    </TableCell>
  )
}

export default TemplateCell