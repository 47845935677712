import React, { useEffect } from 'react';
import { Editor } from 'roosterjs-editor-core';
import { ContentEdit, ImageEdit } from 'roosterjs-editor-plugins'
import { Rooster, Ribbon, createRibbonPlugin } from 'roosterjs-react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import useGetFilteredButtons from '../../../hooks/useGetFilteredButtons';
import TextEditorProps from './TextEditorProps';
import UpdateStatePlugin from './UpdateStatePlugin';
import useSx from './sx';

const ribbonPlugin = createRibbonPlugin();
const contentEdit = new ContentEdit();
const imageEdit = new ImageEdit();

const TextEditor = ({ initialContent, error, helperText, editor }: TextEditorProps) => {
  const sx = useSx();
  const theme = useTheme();
  const { filteredButtons } = useGetFilteredButtons(theme.palette.mode);
  const updateStatePlugin = new UpdateStatePlugin()

  useEffect(() => {
    editor.current?.setContent(initialContent || '')
  }, [])
  

  const roosterClassNames = `rooster ${error ? 'rooster-error' : ''}`;

  return (
    <Box sx={sx.root} flex="1" display="flex" flexDirection="column">
      <Ribbon
        buttons={filteredButtons}
        plugin={ribbonPlugin}
        overflowButtonProps={{
          menuProps: {
            className:
              theme.palette.mode === 'light' ? 'dropdown-menu--light' : 'dropdown-menu--dark',
            items: [],
          },
        }}
      />
      <Box flex="1">
        <Rooster
          className={roosterClassNames}
          doNotAdjustEditorColor
          contentEditable
          plugins={[ribbonPlugin, updateStatePlugin, contentEdit, imageEdit]}
          editorCreator={(div, options) => {
            editor.current = new Editor(div, options);
            div.id = 'editor-div';
            return editor.current;
          }}
        />
      </Box>
      <If condition={error}>
        <Typography component="p" className="error-text">
          {helperText}
        </Typography>
      </If>
    </Box>
  );
};

export default TextEditor;
